import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import {
  addProductApi,
  addProductHolidayOfferApi,
  addProductImageApi,
  addProductLookBookApi,
  bulkProductApi,
  bulkSalesOfferApi,
  checkLookBookTitleApi,
  createDuplicateProductApi,
  deleteProductApi,
  downloadLookBookApi,
  downloadSampleCsvApi,
  getLookBookApi,
  getProductApi,
  getProductCreateSetting,
  getProductsApi,
  getProductSetting,
  getProductViewApi,
  getSubCategoryByCategoryId,
  importProductCsvApi,
  sortProductImageApi,
  updateProductApi,
  updateProductImageAltTextApi,
  createSentImageApi,
  updateAddtionalApi,
  getAdditionalSettingsApi,
  getProductInfoApi,
  exportProductsApi,
  exportProductsToTBOneApi,
  inventoryAdjustmentApi,
  getInventorySettingsApi,
  inventorySkuApi,
  inventoryBinApi,
  addPlatformImageApi,
  skuLocationsApi,
  skuDetailsApi,
  downloadLineSheetPdfApi,
  downloadLineSheetExcelApi,
} from "../../../services/manage-product/productService";
import { toast } from "react-toastify";
import { filerValues } from "../product/edit/content/settings/map";
import { exportNextDayEvriDataApi } from "../../../services/order";

export const getProducts = createAsyncThunk(
  "product/getProducts",
  async (data, { dispatch }) => {
    const response = await getProductsApi(data);
    dispatch(setProductPagination(response.response.pagination));
    return response.response.data;
  }
);
export const getProductSettings = createAsyncThunk(
  "product/getProductSettings",
  async (data) => {
    const response = await getProductSetting(data);
    return response.response;
  }
);
export const getProductCreateSettings = createAsyncThunk(
  "product/getProductSettings",
  async (data) => {
    const response = await getProductCreateSetting(data);
    return response.response;
  }
);
export const getSubCategories = createAsyncThunk(
  "product/getSubCategory",
  async (data) => {
    const response = await getSubCategoryByCategoryId(data);
    return response.response;
  }
);

export const exportProducts = createAsyncThunk(
  "product/exportProducts",
  async (data, { dispatch }) => {
    const response = await exportProductsApi(data);
    return response.response;
  }
);

export const exportProductsToTBOne = createAsyncThunk(
  "product/exportProductsToTBOne",
  async (data, { dispatch }) => {
    const response = await exportProductsToTBOneApi(data);
    return response.response;
  }
);

export const importProductCsv = createAsyncThunk(
  "product/importProductCsv",
  async (data, { dispatch }) => {
    try {
      const response = await importProductCsvApi(data);
      const res = await response.response;
      toast.success("Add products successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return res;
    } catch (e) {
      toast.error(e.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
);

export const addProduct = createAsyncThunk(
  "addProduct",
  async (product, { dispatch, getState }) => {
    try {
      const response = await addProductApi(product);
      const data = await response.response;
      toast.success("Add product successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);
export const downloadSampleCsv = createAsyncThunk(
  "downloadSampleCsv",
  async (product, { dispatch, getState }) => {
    try {
      const response = await downloadSampleCsvApi();
      return await response.response;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);
export const addProductLookBook = createAsyncThunk(
  "addProductLookBook",
  async (product, { dispatch, getState }) => {
    try {
      const formData = new FormData();
      formData.append("myFile", product.image);

      formData.append("title", product.title);
      formData.append("sub_title", product.sub_title);
      formData.append("heading", product.heading);
      formData.append("email", product.email);
      formData.append("contact_name", product.name);
      formData.append("product_ids", product.product_ids);
      formData.append("template", product.template);
      formData.append("dispatch_date", product.dispatch_date);
      formData.append("rrp_price", product.rrp_price);
      formData.append("to_currency", product.to_currency);
      formData.append("exchange_rate", product.exchange_rate);
      formData.append("more_option", product.more_option);

      const response = await addProductLookBookApi(formData);
      const data = await response.response;
      if (response.response.errors) {
        toast.error("The title has already been taken!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.success("Successfully Lookbook created", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const addProductHolidayOffer = createAsyncThunk(
  "addProductHolidayOffer",
  async (product, { dispatch, getState }) => {
    try {
      const response = await addProductHolidayOfferApi(product);
      const data = await response.response;
      toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const checkTitle = createAsyncThunk(
  "product/lookBookTitle",
  async (data) => {
    const response = await checkLookBookTitleApi(data);
    return response.response;
  }
);

export const bulkProductPublish = createAsyncThunk(
  "bulkProductPublish",
  async (product, { dispatch, getState }) => {
    try {
      const response = await bulkProductApi(product);
      const data = await response.response;
      toast.success(data, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);
export const bulkProductSalesOffer = createAsyncThunk(
  "bulkProductSalesOffer",
  async (product, { dispatch, getState }) => {
    try {
      const response = await bulkSalesOfferApi(product);
      const data = await response.response;
      toast.success(data, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const productLookBook = createAsyncThunk(
  "productLookBook",
  async (product, { dispatch, getState }) => {
    const response = await downloadLookBookApi(product);
    return response.response;
  }
);

export const getProduct = createAsyncThunk(
  "getProduct",
  async (id, { dispatch, getState }) => {
    try {
      const response = await getProductApi(id);
      const data = await response.response;
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const getProductView = createAsyncThunk(
  "getProductView",
  async (id, { dispatch, getState }) => {
    try {
      const response = await getProductViewApi(id);
      const data = await response.response;
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const updateProduct = createAsyncThunk(
  "updateProduct",
  async (product, { dispatch, getState }) => {
    try {
      const response = await updateProductApi(product, product.id);
      const data = await response.response;
      toast.success("Update product successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const addProductImage = createAsyncThunk(
  "addProductImage",
  async (imageData, { dispatch, getState }) => {
    try {
      const formData = new FormData();
      let images = [];
      formData.append("shopify_product_id", imageData.shopify_product_id);
      imageData.image.map((item, i) => {
        formData.append("myFile[" + i + "]", item);
      });

      const response = await addProductImageApi(imageData.id, formData);
      const data = await response.response;
      toast.success("Image uploaded successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);
export const deleteProductImage = createAsyncThunk(
  "addProductImage",
  async (id, { dispatch, getState }) => {
    try {
      const response = await deleteProductApi(id);
      const data = await response.response;
      toast.success("Image deleted successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);
export const sortProductImage = createAsyncThunk(
  "sortProductImage",
  async (image, { dispatch, getState }) => {
    try {
      const response = await sortProductImageApi(image.id, image);
      const data = await response.response;
      // toast.success("Image deleted successfully", {
      //     position: toast.POSITION.TOP_RIGHT,
      // });
      return data;
    } catch (e) {
      alert(JSON.stringify(e));
      return Promise.reject(e);
    }
  }
);

export const updateProductImageAlt = createAsyncThunk(
  "sortProductImage",
  async (image, { dispatch, getState }) => {
    try {
      const response = await updateProductImageAltTextApi(image);
      const data = await response.response;
      // toast.success("Image alt Text Saved", {
      //     position: toast.POSITION.TOP_RIGHT,
      // });
      return data;
    } catch (e) {
      alert(JSON.stringify(e));
      return Promise.reject(e);
    }
  }
);

export const getLookBooks = createAsyncThunk(
  "look-book/getLookBooks",
  async (data, { dispatch }) => {
    const response = await getLookBookApi(data);
    dispatch(setLookBookPagination(response.response.pagination));
    return response.response.data;
  }
);
export const createDuplicateProduct = createAsyncThunk(
  "createDuplicateProduct",
  async (product, { dispatch, getState }) => {
    try {
      const response = await createDuplicateProductApi(product);
      const data = await response.response;
      toast.success("Duplicate product created successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const createSentImage = createAsyncThunk(
  "createSentImage",
  async (sentImage, { dispatch, getState }) => {
    try {
      const response = await createSentImageApi(sentImage);
      const data = await response.response;
      toast.success("Image sent created successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const updateAddtional = createAsyncThunk(
  "product/updateAdditional",
  async (data, { dispatch }) => {
    const response = await updateAddtionalApi(data, data.get("id"));
    toast.success(`Additional Details updated successfully`, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response.response;
  }
);

export const getAdditionalSettings = createAsyncThunk(
  "partner/getAdditionalSettings",
  async (id) => {
    const response = await getAdditionalSettingsApi(id);
    return response.response;
  }
);

export const getProductInfo = createAsyncThunk(
  "product/getProductInfo",
  async (id) => {
    const response = await getProductInfoApi(id);
    return response.response;
  }
);

export const getInventorySettings = createAsyncThunk(
  "product/getInventorySettings",
  async () => {
    const response = await getInventorySettingsApi();
    return response.response;
  }
);

export const inventorySku = createAsyncThunk(
  "product/inventorySku",
  async (query) => {
    const response = await inventorySkuApi(query);
    return response.response;
  }
);

export const skuLocations = createAsyncThunk(
  "product/skuLocations",
  async (query) => {
    const response = await skuLocationsApi(query);
    return response.response;
  }
);

export const skuDetails = createAsyncThunk(
  "product/skuDetails",
  async (query) => {
    const response = await skuDetailsApi(query);
    return response.response;
  }
);

export const inventoryBin = createAsyncThunk(
  "product/inventoryBin",
  async (query) => {
    const response = await inventoryBinApi(query);
    return response.response;
  }
);

export const downloadLineSheetExcel = createAsyncThunk(
  "product/downloadLineSheetExcel",
  async (data, { dispatch }) => {
    const response = await downloadLineSheetExcelApi(data.ids);
    return response.response;
  }
);

const productAdapter = createEntityAdapter({});

export const { selectAll: selectProduct, selectById: selectProductById } =
  productAdapter.getSelectors((state) => state.product.product);

const productSlice = createSlice({
  name: "products",
  initialState: productAdapter.getInitialState({
    searchText: "",
    addDialog: {
      component: "Product",
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
    mediaDialogProps: {
      props: {
        isOpen: false,
      },
    },
    ProductDialogs: {
      props: {
        open: false,
      },
    },
    skuDialogProps: {
      props: {
        isOpen: false,
      },
    },
    actionDialogProps: {
      title: "",
      type: 1,
      props: {
        isOpen: false,
      },
    },
    productDialogProps: {
      props: {
        isOpen: false,
      },
    },
    addLookBookDialog: {
      component: "Look Book",
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
    addHolidayOfferDialog: {
      title: "",
      type: 1,
      props: {
        isOpen: false,
      },
      data: null,
    },
    importProductDialog: {
      title: "",
      type: 1,
      props: {
        isOpen: false,
      },
      data: null,
    },
    addDialogs: {
      component: "Additional Details",
      type: "new",
      props: {
        open: false,
      },
      data: null,
    },
    submitConfirmDialogs: {
      title: "",
      type: "submit",
      props: {
        open: false,
      },
      data: null,
    },
    filter: filerValues(),
    additionalDetailsSettings: {
      fabrics: [],
      colours: [],
      necklines: [],
      patterns: [],
      sleeve_lengths: [],
      women_styles: [],
      occasions: [],
    },
    productInfoSet: {
      id: "",
      title: "",
      description: "",
      product_type: "",
      vendor: "",
      variants: [],
      options: [],
      images: [],
    },
    productIndexSettings: {
      brands: [],
      categories: [],
      tags: [],
      commodities: [],
      countries: [],
      seasons: [],
      styles: [],
      compositions: [],
      tag_settings: [],
      group_product: [],
    },
    pagination: {
      count: 1,
      total: 1,
      page_total: 1,
    },
    productSettings: {
      brands: [],
      categories: [],
      sub_categories: [],
      styles: [],
      colors: [],
      sizes: [],
      size_matrix: [],
      types: [],
      countries: [],
      seasons: [],
      selling_types: [],
      tags: [],
      compositions: [],
      commodities: [],
      fit_types: [],
      fit_type_conditions: [],
      tag_settings: [],
    },
    product: null,
    lookBook: null,
    lookBookTableRow: 0,
    additionalDetails: [],
    settings: {
      stockType: [],
      reasonType: [],
      locations: [],
      productConditionType: [],
      user: [],
      mainUser: [],
    },
  }),
  reducers: {
    setSettings: (state, action) => {
      state.settings.stockType = action.payload.stockType;
      state.settings.reasonType = action.payload.reasonType;
      state.settings.locations = action.payload.locations;
      state.settings.productConditionType = action.payload.productConditionType;
      state.settings.user = action.payload.user;
      state.settings.mainUser = action.payload.mainUser;
    },
    openNewDialog: (state, action) => {
      state.addDialog = {
        component: "Product",
        type: "new",
        props: {
          isOpen: true,
        },
        data: null,
      };
    },
    closeNewDialog: (state, action) => {
      state.addDialog = {
        type: "update",
        props: {
          isOpen: false,
        },
        data: null,
      };
    },
    openDialogs: (state, action) => {
      state.addDialogs = {
        component: "Additional Details",
        type: "update",
        props: {
          isOpen: true,
        },
        data: { hasData: true },
      };
    },
    closeDialog: (state, action) => {
      state.addDialogs = {
        type: "new",
        props: {
          isOpen: false,
        },
        data: null,
      };
    },
    openProductInfoDialogs: (state, action) => {
      state.ProductDialogs = {
        props: {
          isOpen: true,
        },
      };
    },
    closeProductInfoDialog: (state, action) => {
      state.ProductDialogs = {
        props: {
          isOpen: false,
        },
      };
    },
    setAdditionalDetailsSettings: (state, action) => {
      state.additionalDetailsSettings = action.payload;
    },
    setProductInfo: (state, action) => {
      state.productInfoSet = action.payload;
    },
    setProductIndexSettings: (state, action) => {
      state.productIndexSettings = action.payload;
    },
    setProductSettings: (state, action) => {
      state.productSettings = action.payload;
    },
    setProductSubCategory: (state, action) => {
      state.productSettings.sub_categories = action.payload;
    },
    setProductPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setLookBookPagination: (state, action) => {
      state.lookBookPagination = action.payload;
    },
    setMediaDialog: (state, action) => {
      state.mediaDialogProps = action.payload;
    },
    setSkuDialog: (state, action) => {
      state.skuDialogProps = action.payload;
    },
    setNormalSkuDialog: (state, action) => {
      state.skuDialogProps = action.payload;
    },
    setProductDialog: (state, action) => {
      state.productDialogProps = action.payload;
    },
    setActionDialog: (state, action) => {
      state.actionDialogProps = action.payload;
    },
    setProductData: (state, action) => {
      state.product = action.payload;
    },
    setLookBookData: (state, action) => {
      state.lookBook = action.payload;
    },
    setProductFilter: (state, action) => {
      state.filter = action.payload;
    },
    setSubmitConfirmDialog: (state, action) => {
      state.submitConfirmDialogs = action.payload;
    },
    openLookBookDialog: (state, action) => {
      state.addLookBookDialog = {
        component: "Look Book",
        type: "new",
        props: {
          isOpen: true,
        },
        data: null,
      };
    },
    closeLookBookDialog: (state, action) => {
      state.addLookBookDialog = {
        type: "new",
        props: {
          isOpen: false,
        },
        data: null,
      };
    },

    openHolidayOfferDialog: (state, action) => {
      state.addHolidayOfferDialog = {
        component: "Holiday Offer",
        type: "new",
        props: {
          isOpen: true,
        },
        data: null,
      };
    },
    closeHolidayOfferDialog: (state, action) => {
      state.addHolidayOfferDialog = {
        type: "new",
        props: {
          isOpen: false,
        },
        data: null,
      };
    },
    openImportProductDialog: (state, action) => {
      state.importProductDialog = {
        type: "new",
        props: {
          isOpen: true,
        },
        data: null,
      };
    },
    closeImportProductDialog: (state, action) => {
      state.importProductDialog = {
        type: "new",
        props: {
          isOpen: false,
        },
        data: null,
      };
    },
    setAdditionalDetails: (state, action) => {
      state.additionalDetails = action.payload;
    },
  },
  extraReducers: {
    [getProducts.fulfilled]: productAdapter.setAll,
    // [updateStaff.fulfilled]: staffsAdapter.setAll
    // [deleteStaff.fulfilled]: staffsAdapter.removeOne,
  },
});

export const {
  openNewDialog,
  closeNewDialog,
  setProductSettings,
  setProductIndexSettings,
  setProductSubCategory,
  setProductPagination,
  setLookBookPagination,
  setMediaDialog,
  setSkuDialog,
  setNormalSkuDialog,
  setProductDialog,
  setProductData,
  setLookBookData,
  setProductFilter,
  openLookBookDialog,
  openHolidayOfferDialog,
  closeHolidayOfferDialog,
  closeLookBookDialog,
  setActionDialog,
  openImportProductDialog,
  closeImportProductDialog,
  openDialogs,
  closeDialog,
  openProductInfoDialogs,
  closeProductInfoDialog,
  setProductInfo,
  setAdditionalDetailsSettings,
  setAdditionalDetails,
  setSettings,
  setSubmitConfirmDialog,
} = productSlice.actions;

export default productSlice.reducer;

import { numberWithCurrency } from "../../../../utils/common";

export const saleOrderPaymentCalculation = (getValues, currency = "") => {
  let subTotalPrice = 0;
  let discountAmount = 0;
  let discountAmountTax = 0;
  let shippingAmountTax = 0;
  let shippingAmount = 0;
  let taxAmount = 0;
  let dutyAmount = 0;
  let itemWiseDiscount = 0;
  let grossAmount = 0;
  const discount = getValues("discount");
  const orders = getValues("ordered_products");
  const shipping = getValues("shipping");
  const tax = getValues("tax");
  const duty = getValues("duty");
  const is_vat = getValues("is_vat");
  const billingAddress = getValues("billingAddress");
  const symbol = currency;

  if (orders.length > 0) {
    orders.map(function (item, index) {
      itemWiseDiscount += item.item_discount ? item.discount : 0;
      subTotalPrice += item.total_price ? item.total_price : 0;
      grossAmount += item.gross_amount ? item.gross_amount : 0;
    });
  }
  if (discount) {
    if (discount.discount_type == 1) {
      discountAmount = Number(discount.value);
      discountAmount = Number(discount.value);
    } else {
      discountAmount = subTotalPrice * (Number(discount.value) / 100);
    }

    if (is_vat == 1) {
      discountAmountTax = discountAmount * 0.2;
    }
  }
  if (duty) {
    if (duty.type == 1) {
      dutyAmount = Number(duty.value);
    } else {
      dutyAmount = subTotalPrice * (Number(duty.value) / 100);
    }
  }

  if (shipping) {
    if (shipping.shipping_type == 2) {
      shippingAmount = Number(shipping.price);
    }

    if (is_vat == 1) {
      shippingAmountTax = shippingAmount * 0.2;
    }
  }
  if (tax) {
    if (tax.is_charge) {
      const totalTaxablePrice = subTotalPrice - discountAmount;
      taxAmount = Number(tax.percentage);
      taxAmount = taxAmount - discountAmountTax;
    }
  }

  let total =
    subTotalPrice +
    shippingAmount +
    dutyAmount +
    taxAmount -
    discountAmount -
    itemWiseDiscount;
  let subTotalWithDiscount = subTotalPrice - discountAmount - itemWiseDiscount;
  return {
    subTotal: numberWithCurrency(subTotalPrice, symbol),
    itemDiscount: numberWithCurrency(itemWiseDiscount, symbol),
    total: numberWithCurrency(total, symbol),
    discountPrice: numberWithCurrency(discountAmount, symbol),
    shippingPrice: numberWithCurrency(shippingAmount, symbol),
    taxPrice: numberWithCurrency(taxAmount, symbol),
    dutyPrice: numberWithCurrency(dutyAmount, symbol),
    subTotalWithDiscount: numberWithCurrency(subTotalWithDiscount, symbol),
  };
};

const groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const orderCommodities = function (orders, currency = "") {
  const data = [];
  const commodities = groupBy(orders, "commodity");
  let totalQuantity = 0;
  let totalPrice = 0;
  for (const [key, value] of Object.entries(commodities)) {
    if (key) {
      let price = value
        .map((o) => o.total_price)
        .reduce((a, c) => {
          return a + c;
        });

      let quantity = value
        .map((o) => o.quantity)
        .reduce((a, c) => {
          return a + c;
        });
      totalPrice += price;
      totalQuantity += quantity;

      data.push({
        commodity: key,
        amount: numberWithCurrency(price, currency),
        quantity: quantity,
      });
    }
  }

  data.push({
    commodity: "Total",
    amount: numberWithCurrency(totalPrice, currency),
    quantity: totalQuantity,
  });
  return data;
};

export const productTaxAmount = function (getValues) {
  let totalTaxAmount = 0;
  getValues("ordered_products").map(function (item, index) {
    totalTaxAmount += item.tax_amount ? item.tax_amount : 0;
  });
  return totalTaxAmount;
};

export const shippingTaxAmount = function (getValues) {
  const shipping = getValues("shipping");
  let shippingtaxAmount = 0;
  if (shipping) {
    shippingtaxAmount = Number(shipping.price) * 0.2;
  }
  return shippingtaxAmount;
};

import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { draftOrderFilters } from "../../manage-product/product/edit/content/settings/map";
import {
  addSalesOrderFulfillmentApi,
  editSaleOrderFulfillmentApi,
  getFulfillmentByOrderApi,
  getFulfillmentCreateDataApi,
  getPendingOrderFulfillmentAPi,
  updateSalesOrderFulfillmentApi,
  getFulfillmentApi,
  closeSalesOrderFulfillmentApi,
} from "../../../services/manage-order/saleOrderFulfillmentService";

export const getAllFulfillments = createAsyncThunk(
  "sales-order-fulfilment/getFulfillment",
  async (filters, { dispatch }) => {
    const response = await getFulfillmentApi(filters);
    dispatch(setTableRowCount(response.response.count));
    return response.response.data;
  }
);

export const getFulfillmentByOrder = createAsyncThunk(
  "sales-order-fulfilment/show",
  async (id, { dispatch }) => {
    const response = await getFulfillmentByOrderApi(id);
    return response.response;
  }
);

export const getPendingSaleOrderFulfillmentItems = createAsyncThunk(
  "sales-order-fulfilment/show",
  async (id, { dispatch }) => {
    const response = await getPendingOrderFulfillmentAPi(id);
    return response.response;
  }
);

export const getCreateData = createAsyncThunk(
  "sales-order-fulfilment/create-data",
  async (id, { dispatch }) => {
    const response = await getFulfillmentCreateDataApi(id);
    return response.response;
  }
);
export const addSalesOrderFulfillment = createAsyncThunk(
  "sales-order-fulfilment/add",
  async (orderData, { dispatch, getState }) => {
    try {
      const response = await addSalesOrderFulfillmentApi(orderData);
      const data = await response.response;
      toast.success("Sales order Fulfillment Created Successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);
export const editSaleOrderFulfilment = createAsyncThunk(
  "sales-order-fulfilment/create-data",
  async (id, { dispatch }) => {
    const response = await editSaleOrderFulfillmentApi(id);
    return response.response;
  }
);

export const updateSalesOrderFulfillment = createAsyncThunk(
  "sales-order-fulfilment/update",
  async (orderData, { dispatch }) => {
    const response = await updateSalesOrderFulfillmentApi(
      orderData,
      orderData.id
    );
    toast.success(`Sales order Fulfillment Updated Successfully`, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response.response;
  }
);

export const closeCurrentSalesOrderFulfillment = createAsyncThunk(
  "sales-order-fulfilment/close",
  async (orderData, { dispatch }) => {
    const response = await closeSalesOrderFulfillmentApi(orderData, orderData.id);
    toast.success(`Sales Order Fulfillment Closed Successfully`, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return response.response;
  }
);

const salesOrderFulfillmentAdapter = createEntityAdapter({});

export const {
  selectAll: selectFulfillment,
  selectById: selectFulfillmentById,
} = salesOrderFulfillmentAdapter.getSelectors(
  (state) => state.order.salesOrderFulfilment
);

const saleOrderFulfillmentSlice = createSlice({
  name: "saleOrderFulfillment",
  initialState: salesOrderFulfillmentAdapter.getInitialState({
    id: null,
    noOfTableRows: 0,
    searchText: "",
    data: null,
    settings: {
      countries: [],
      shippings: [],
    },
    formSettings: {
      delivery_partners: [],
    },
    orderFulfillment: [],
    filter: draftOrderFilters(),
  }),
  reducers: {
    setSalesOrderFilter: (state, action) => {
      state.filter = action.payload;
    },
    setTableRowCount: (state, action) => {
      state.noOfTableRows = action.payload;
    },
    setOrderFulfillment: (state, action) => {
      state.orderFulfillment = action.payload;
    },
    setFromSettings: (state, action) => {
      state.formSettings = action.payload;
    },
  },
  extraReducers: {
    [getAllFulfillments.fulfilled]: salesOrderFulfillmentAdapter.setAll,
  },
});

export const { setTableRowCount, setOrderFulfillment, setFromSettings } =
  saleOrderFulfillmentSlice.actions;

export default saleOrderFulfillmentSlice.reducer;
import React, {useState} from 'react';
import ModalFormSubmit from "../../../../../share-components/form/modalFormSubmit";
import ProductCategoryForm from "../../../../manage-product/product-setting/product-category/productCategoryForm";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import Select from "react-select";
import {closeEditDialog} from "../../../../manage-product/store/productCategorySlice";
import {useDispatch, useSelector} from "react-redux";
import {Col, FormGroup, Input, Label, Row} from "reactstrap";
import {setOrderDiscountDialog, setShippingDiscountDialog} from "../../../store/draftOrderSlice";
import {optionDefaultValue} from "../../../../../utils/common";
import {productTaxAmount, shippingTaxAmount} from "../../../sales-order/setting/calculation";

const defaultValues = {
    shipping_type: 2,
    rate_name: '',
    price: '',
};
const schema = yup.object().shape({
    shipping_type: yup.string().required('You must select typ'),
    rate_name: yup.string().required('You must enter name'),
    price: yup.number().typeError('must be a number').required('this field is required').min(0,"should be grater than or equal to 0")


});


function OrderShipment(props) {
    const dispatch = useDispatch();
    const {orderSetValue, orderTrigger, orderGetValues,draftOrderState} = props;
    const [buttonDisable, setButtonDisable] = useState(false);
    const {handleSubmit, formState, reset, control, register, setValue, handleChange, trigger, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const {errors} = formState;
    const onSubmitForm = (dialog, data) => {
        setButtonDisable(true);
        orderSetValue('shipping', data);
        const productTax=productTaxAmount(orderGetValues);
        console.log(productTax);
        if(productTax > 0){
            const totalTax=shippingTaxAmount(orderGetValues);
            orderSetValue('tax', {is_charge: true, rate_name: 'Vat', percentage: productTax+totalTax})
        }

        orderTrigger('shipping');
        setButtonDisable(false);
        closeDialog();

    }
    const closeDialog = () => {
        const obj = {props: {isOpen: false}, type: 'new', data: ""};
        dispatch(setShippingDiscountDialog(obj));

    }
    const shippingTypeChange=(e)=>{
        setValue('shipping_type',e.target.value)
        trigger('shipping_type');
    }
    const orderState = useSelector(({order}) => order.draftOrder);


    return (
        <ModalFormSubmit
            defaultValues={defaultValues}
            schema={schema}
            onSubmitForm={onSubmitForm}
            reset={reset}
            formState={formState}
            handleSubmit={handleSubmit}
            title='Shipping'
            selector='shipping'
            isUseSelector
            dialog={orderState.shippingDialogProps}
            closeDialog={closeDialog}
            buttonDisable={buttonDisable}
        >

            {/*<Row>*/}
            {/*    <Col md={12}>*/}
            {/*        <FormGroup>*/}
            {/*            <div className="custom-control custom-radio">*/}
            {/*                <Input id="freeShipping" name="shipping" type="radio" value="1" onChange={shippingTypeChange} defaultChecked={1 == getValues('shipping_type')}*/}
            {/*                       className="custom-control-input form-check-input"/>*/}
            {/*                    <Label htmlFor="freeShipping"  className="custom-control-label" >Free</Label>*/}
            {/*            </div>*/}
            {/*            <div className="custom-control custom-radio">*/}
            {/*                <Input id="custom" name="shipping" type="radio"  value="2" onChange={shippingTypeChange} defaultChecked={2 == getValues('shipping_type')}*/}
            {/*                       className="custom-control-input form-check-input"/>*/}
            {/*                <Label htmlFor="custom" className="custom-control-label" >Custom</Label>*/}
            {/*            </div>*/}
            {/*        </FormGroup>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            {getValues('shipping_type') ==2 &&<Row>
                <Col md={6}>
                    <FormGroup>
                        <Label className="col-form-label">Rate Name</Label>

                        <Select placeholder="Shipping Name" name="shipping" getOptionLabel={(option) => option.name}
                                value={optionDefaultValue(draftOrderState.settings.shippings,getValues('rate_name'))}
                                getOptionValue={(option) => option.id} {...register("rate_name")}
                                onChange={(e) => {
                                    setValue('rate_name', e.name);
                                    trigger();
                                }} className={` ${errors.country ? 'is-invalid' : ''}`}
                                options={draftOrderState.settings.shippings}/>
                        <div style={{color:'red'}}>{errors.rate_name?.message}</div>
                    </FormGroup>

                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label className="col-form-label">Price</Label>
                        <input name="value" type="number" {...register('price')} step="any"
                               className={`form-control ${errors.price ? 'is-invalid' : ''}`}/>
                        <div className="invalid-feedback">{errors.price?.message}</div>
                    </FormGroup>

                </Col>
            </Row>}
            {orderGetValues('shipping') && <Row>
                <Col md={12}>
                    <button className="btn btn-danger btn-sm float-right" onClick={() => {
                        orderSetValue('shipping', "");
                        orderTrigger('shipping');
                        closeDialog();
                    }
                    }>Remove <i className="fa fa-trash"></i></button>
                </Col>
            </Row>}

        </ModalFormSubmit>
);
}

export default OrderShipment;
import React, { Fragment, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
  Media,
  Button,
  Form,
} from "reactstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  addToCart,
  getShoppingProductById,
  setCartItemData,
  setProductData,
  updateToCart,
} from "../store/shoppingSlice";
import { updateInventoryAdjustment } from "../../manage-product/store/inventorySlice";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

const defaultValues = {
  product_id: "",
  orders: [],
};
const ShoppingProductOrder = (props) => {
  const dispatch = useDispatch();
  const { shoppingState } = props;
  const colourVariants = shoppingState.product.variants.filter(function (v, i) {
    return v.colour_id == shoppingState.product.selected_colour.id;
  });

  const sumQuantity = (array, key) => {
    return array.reduce(
      (accumulator, currentValue) => accumulator + Number(currentValue[key]),
      0
    );
  };
  const {
    handleSubmit,
    formState,
    reset,
    control,
    register,
    setValue,
    trigger,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const { fields, append, remove } = useFieldArray({ name: "orders", control });
  const { errors, isDirty } = formState;
  useEffect(() => {
    if (shoppingState.productViewDialogProps.props.isOpen) {
      const orderItems = [];
      shoppingState.cartItems.map((item, ind) => {
        const orderItem = shoppingState.product.variants.find(
          (variantItem) => variantItem.id === item.variant_id
        );
        if (orderItem) {
          const orderQuantity = getOrderQuantity(item.quantity, orderItem);
          orderItems.push({
            variant_id: item.variant_id,
            product_id: item.product_id,
            quantity: item.quantity,
            colour_id: item.colour_id,
            future_quantity: orderQuantity.futureQuantity,
            immediate_quantity: orderQuantity.immediateQuantity,
            pre_order_quantity: orderQuantity.futureQuantity,
          });
        }
      });
      reset({ orders: orderItems, product_id: shoppingState.product.id });
    }
  }, [shoppingState.product]);
  const handleCheckBin = (item) => {
    const indexToRemove = findBinIndex(item);
    if (indexToRemove == -1) {
      append({
        variant_id: item.id,
        product_id: item.product_id,
        colour_id: item.colour_id,
        quantity: "",
        future_quantity: 0,
        immediate_quantity: 0,
        pre_order_quantity: 0,
      });
      trigger("orders");
    }
  };
  const findBinIndex = (variantItem) => {
    return getValues("orders").findIndex(
      (item) => item.variant_id === variantItem.id
    );
  };

  const findItem = (variantItem) => {
    const data = getValues("orders").find(
      (item) => item.variant_id === variantItem.id
    );
    return data ? data : "";
  };
  const onSubmit = (data) => {
    const cartId = localStorage.getItem("cart-id");
    const dataParams = {
      id: cartId,
      data: data,
    };
    if (cartId) {
      dispatch(updateToCart({ ...dataParams })).then((res) => {
        dispatch(setCartItemData(res.payload.items));
      });
    } else {
      dispatch(addToCart({ ...data })).then((res) => {
        localStorage.setItem("cart-id", res.payload.id);
        dispatch(setCartItemData(res.payload.items));
      });
    }
  };
  const orderButtonDisable = () => {
    return (
      getValues("orders").filter(function (v, i) {
        return v.quantity > 0;
      }).length < 1
    );
  };

  const getOrderQuantity = (orderQty, item) => {
    // setValue(`orders.${variantIndex}.quantity`, e.target.value);
    const orderQuantity = Number(orderQty);
    const immediateQuantity = Math.min(orderQuantity, Number(item.quantity));
    const balanceImmediate = orderQuantity - immediateQuantity;
    const futureQuantity = Math.min(
      balanceImmediate,
      Number(item.future_quantity)
    );
    const balanceFuture = balanceImmediate - futureQuantity;
    return {
      immediateQuantity: immediateQuantity,
      futureQuantity: futureQuantity,
      preOrderQuantity: balanceFuture,
    };
  };

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();
  let futureMonth = currentMonth + 2; 
  let futureYear = currentYear;
  if (futureMonth > 12) {
    futureMonth -= 12;
    futureYear++;
  }
  const firstDayOfMonth = new Date(currentYear, currentMonth - 1, 1);
  const lastDayOfMonth = new Date(currentYear, currentMonth, 0);
  const firstDayOfFutureMonth = new Date(futureYear, futureMonth - 1, 1);
  const lastDayOfFutureMonth = new Date(futureYear, futureMonth, 0);
  const formatDate = (date) => {
    return `${date.toLocaleString("default", {
      month: "short",
    })} ${date.getDate()} ${date.getFullYear()}`;
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="mt-3">
        <Col md={12}>
          <div className="-100 table-container">
            <Table className="shopping-cart-table">
              <tbody>
                <tr>
                  <td width="2%"></td>
                  <td
                    className="fixed-column"
                    style={{ minWidth: "275px" }}
                  ></td>

                  {colourVariants.map((item, i) => {
                    return (
                      <td key={i}>
                        <div>{item.name}</div>
                        <div className="mt-1">
                          <input
                            onFocus={() => {
                              handleCheckBin(item);
                            }}
                            key={shoppingState.product.selected_colour.id}
                            defaultValue={getValues(
                              `orders.${findBinIndex(item)}.quantity`
                            )}
                            onChange={(e) => {
                              handleCheckBin(item);
                              const variantIndex = findBinIndex(item);
                              if (variantIndex != -1) {
                                const orderQuantity = getOrderQuantity(
                                  e.target.value,
                                  item
                                );
                                setValue(
                                  `orders.${variantIndex}.quantity`,
                                  e.target.value
                                );
                                setValue(
                                  `orders.${variantIndex}.immediate_quantity`,
                                  orderQuantity.immediateQuantity
                                );
                                setValue(
                                  `orders.${variantIndex}.future_quantity`,
                                  orderQuantity.futureQuantity
                                );
                                setValue(
                                  `orders.${variantIndex}.pre_order_quantity`,
                                  orderQuantity.preOrderQuantity
                                );
                                trigger(`orders.${variantIndex}.quantity`);
                              }
                            }}
                            className="form-control"
                            style={{ height: 30, width: 50 }}
                          />
                        </div>
                      </td>
                    );
                  })}
                  <td className="fixed-column-right">
                    Total
                    <div>
                      {sumQuantity(
                        getValues("orders").filter(function (v, i) {
                          return (
                            v.colour_id ==
                            shoppingState.product.selected_colour.id
                          );
                        }),
                        "quantity"
                      )}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    rowSpan={3}
                    className="vertical-text "
                    style={{ padding: 0, background: "silver" }}
                  >
                    Available
                  </td>
                  <td className="fixed-column">
                    <span className="badge bg-success w-25">IMMEDIATE</span>
                    {/* <span className="f-12"> FEB 01 2024- FEB 29 2024</span> */}
                    <span className="f-12">
                      {" "}

                    </span>
                  </td>
                  {colourVariants.map((item, i) => {
                    return (
                      <td key={i}>
                        {findItem(item)?.immediate_quantity} /{item.quantity}
                      </td>
                    );
                  })}
                  <td className="fixed-column-right">
                    {sumQuantity(colourVariants, "quantity")}
                  </td>
                </tr>
                <tr>
                  <td className="fixed-column">
                    <span className="badge bg-warning  w-25">FUTURE</span>
                    {/* <span className="f-12"> FEB 01 2024- FEB 29 2024</span> */}
                    <span className="f-12">
                      {" "}
                      {shoppingState.product.future_delivery}
                    </span>
                  </td>
                  {colourVariants.map((item, i) => {
                    return (
                      <td key={i}>
                        {findItem(item)?.future_quantity}/{item.future_quantity}
                      </td>
                    );
                  })}
                  <td className="fixed-column-right">
                    {sumQuantity(colourVariants, "future_quantity")}
                  </td>
                </tr>
                <tr>
                  <td className="fixed-column">
                    <span className="badge bg-danger  w-25">PRE BOOK</span>
                  </td>
                  {colourVariants.map((item, i) => {
                    return (
                      <td key={i}>{findItem(item)?.pre_order_quantity}</td>
                    );
                  })}
                  <td className="fixed-column-right">
                    {sumQuantity(
                      getValues("orders").filter(function (v, i) {
                        return (
                          v.colour_id ==
                          shoppingState.product.selected_colour.id
                        );
                      }),
                      "pre_order_quantity"
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
      {/* <Row className="mt-3">
                <Col md={12}>
                    <p style={{color: 'red'}}>red note</p>
                </Col>
            </Row> */}
      <Row className="mt-5">
        <Col md={6}>
          {shoppingState.cartItems.length > 0 && (
            <Link
              className="btn btn-block btn-primary"
              to={`${process.env.PUBLIC_URL}/shopping-product/checkout`}
            >
              GO TO ORDER LIST
            </Link>
          )}
        </Col>
        <Col md={6}>
          <Button
            className="btn btn-block btn-primary"
            disabled={orderButtonDisable()}
            color="primary"
          >
            ADD TO ORDER LIST
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ShoppingProductOrder;

import API from "../../../utils/api";

export const getPaymentMethodsApi = async (filters) => {
  const response = await API.get("settings/payment-method", {
    params: filters,
  });
  return { response };
};

export const addPaymentMethodApi = async (location) => {
  const response = await API.post("settings/payment-method", location);
  return { response };
};

export const updatePaymentMethodApi = async (location, id) => {
  const response = await API.put(`settings/payment-method/${id}`, location);
  return { response };
};

export const deletePaymentMethodApi = async (id) => {
  const response = await API.delete(`settings/payment-method/${id}`);
  return { response };
};

export const getPaymentAutoSuggestionApi = async (data) => {
  console.log(data);
  const response = await API.get("order/payment-auto-search", {
    params: data,
  });
  return { response };
};

import React, { useRef } from "react";
import {
  Modal,
  ModalBody,
  Container,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { setSubmitConfirmDialog } from "../../../store/prodcutSlice";

const ProductConfirmSubmit = ({ handleConfirm }) => {
  const dispatch = useDispatch();
  const productSubmitDialogProps = useSelector(
    (state) => state.product.product.submitConfirmDialogs
  );

  const shopifyRef = useRef();
  const wholesaleRef = useRef();

  const toggleModal = () => {
    closeDialog();
  };

  const closeDialog = () => {
    const obj = { props: { isOpen: false } };
    dispatch(setSubmitConfirmDialog(obj));
  };

  const handleSaveAndPublish = () => {
    const settings = {
      shopify: shopifyRef.current.checked,
      wholesale: wholesaleRef.current.checked,
    };
    handleConfirm(settings);
    closeDialog();
  };

  return (
    <Modal
      isOpen={productSubmitDialogProps.props.isOpen}
      toggle={toggleModal}
      size="md"
    >
      <ModalBody>
        {productSubmitDialogProps.data && (
          <Container fluid={true}>
            <div className="text-center p-3">
              <div className="custom-sweetalert">
                <h5 className="custom-sweetalert-title p-2">Publish On</h5>
                <div className="custom-sweetalert-text p-2 d-flex justify-content-center">
                  {/* <FormGroup check inline className="pr-3">
                    <Label check>
                      <Input type="checkbox" innerRef={shopifyRef} /> Shopify
                    </Label>
                  </FormGroup> */}
                  <FormGroup check inline className="pl-3">
                    <Label check>
                      <Input type="checkbox" innerRef={wholesaleRef} />{" "}
                      Wholesale Portal
                    </Label>
                  </FormGroup>
                </div>
                <div className="custom-sweetalert-buttons p-2">
                  <Button
                    color="secondary"
                    className="mr-1"
                    onClick={closeDialog}
                  >
                    No, Cancel
                  </Button>
                  <Button color="success" onClick={handleSaveAndPublish}>
                    Save & Publish
                  </Button>
                </div>
              </div>
            </div>
          </Container>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ProductConfirmSubmit;

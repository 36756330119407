import React, { Fragment, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Media,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
} from "reactstrap";
import radialimage from "../../../../assets/images/dashboard-2/radial-image.png";

import ApexCharts from "react-apexcharts";

import { useDispatch, useSelector } from "react-redux";
import {
  getProduct,
  getProductView,
  setMediaDialog,
  setProductData,
  setProductDialog,
  setProductFilter,
  setProductSubCategory,
} from "../../store/prodcutSlice";
import PreLoader from "../../../../share-components/ui/preLoader";
import { numberWithCommas } from "../../../../utils/common";
import Select from "react-select";
import ShoppingProductOrder from "../../../shopping/content/shoppingProductOrder";

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, left: "20px", zIndex: 1 }} // Adjust left position as needed
      onClick={onClick}
    >
      Previous
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, right: "20px", zIndex: 1 }} // Adjust right position as needed
      onClick={onClick}
    >
      Next
    </div>
  );
};

const settings = {
  dots: true,
  fade: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  waitForAnimate: false,
  prevArrow: <CustomPrevArrow />,
  nextArrow: <CustomNextArrow />,
};

const ProductView = (props) => {
  const {} = props;
  const [state, setState] = useState({ nav1: null, nav2: null });
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("1");
  const productState = useSelector(
    ({ product }) => product.product.productDialogProps
  );
  const product = useSelector(({ product }) => product.product.product);
  const productId = productState.data ? productState.data.id : "";
  const colourVariants = product
    ? product.variants.filter(function (v, i) {
        return v.colour_id == product.selected_colour.id;
      })
    : [];
  const slider1 = useRef();
  const slider2 = useRef();
  const closDialog = () => {
    dispatch(setProductDialog({ props: false }));
    setActiveTab("1");
    setLoading(true);
  };
  useEffect(() => {
    if (productId) {
      dispatch(getProductView(productId)).then((res) => {
        if (!res.error) {
          dispatch(setProductData(res.payload.product));
          setLoading(false);
          setState({
            nav1: slider1.current,
            nav2: slider2.current,
          });
        }
      });
    }
  }, [productId]);

  const { nav1, nav2 } = state;

  return (
    <>
      <Modal
        className="fullscreen-modal modal-dialog-centered product-modal"
        size="xl"
        {...productState.props}
      >
        <ModalBody>
          <ModalHeader
            toggle={() => {
              closDialog();
            }}
          ></ModalHeader>
          {!loading ? (
            <div style={{ width: "100%" }}>
              {product && (
                <Row>
                  <Col md={4}>
                    <div className="slider-container">
                      {product.images && (
                        <Slider {...settings}>
                          {product.images.map((item, i) => {
                            return (
                              <div className="item" key={i}>
                                <div className="text-center ">
                                  <Media
                                    src={item.image_path}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </Slider>
                      )}
                    </div>
                  </Col>
                  <Col md={8}>
                    <Row>
                      <Col md={6}>
                        <Row>
                          <Col md={12}>
                            <span className="f-w-600 f-16">{product.name}</span>
                          </Col>
                          <Col md={12}>
                            <span className="f-w-100 f-12">{product.code}</span>
                          </Col>
                          <Col md={12}>
                            <span className="f-w-100 f-12">
                              Selling Price :{" "}
                            </span>{" "}
                            <span className="f-w-600 f-14">
                              $
                              {product.sale_price
                                ? product.sale_price
                                : product.price}
                            </span>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={6}>
                        <Select
                          options={product.colours}
                          value={product.selected_colour}
                          getOptionValue={(option) => option.id}
                          onChange={(e) => {
                            dispatch(
                              setProductData({
                                ...product,
                                selected_colour: e,
                              })
                            );
                          }}
                          getOptionLabel={(option) => (
                            <div>
                              <span
                                style={{
                                  backgroundColor: option.code,
                                  width: "20px",
                                  height: "20px",
                                  display: "inline-block",
                                  marginRight: "5px",
                                  marginTop: 5,
                                }}
                              ></span>
                              <span style={{ marginTop: "-10px" }}>
                                {" "}
                                {option.name}
                              </span>
                            </div>
                          )}
                        />
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col md={12}>
                        <p>{product.meta_description}</p>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md={6}>
                        <Row>
                          <Col md={6}>
                            <span className="f-w-600">Brand</span>
                          </Col>
                          <Col md={6}>
                            <span className="f-w-100">{product.brand}</span>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={6}>
                        <Row>
                          <Col md={6}>
                            <span className="f-w-600">Type</span>
                          </Col>
                          <Col md={6}>
                            <span className="f-w-100">{product.type}</span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md={6}>
                        <Row>
                          <Col md={6}>
                            <span className="f-w-600">Category</span>
                          </Col>
                          <Col md={6}>
                            <span className="f-w-100">{product.category}</span>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={6}>
                        <Row>
                          <Col md={6}>
                            <span className="f-w-600">Sub Category</span>
                          </Col>
                          <Col md={6}>
                            <span className="f-w-100">
                              {product.sub_category}
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                   
                    <Row className="mt-3">
                      <Col md={6}>
                        <Row>
                          <Col md={6}>
                            <span className="f-w-600">Season</span>
                          </Col>
                          <Col md={6}>
                            <span className="f-w-100">{product.season}</span>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={6}>
                        <Row>
                          <Col md={6}>
                            <span className="f-w-600">Year</span>
                          </Col>
                          <Col md={6}>
                            <span className="f-w-100">{product.year}</span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md={6}>
                        <Row>
                          <Col md={6}>
                            <span className="f-w-600">Composition</span>
                          </Col>
                          <Col md={6}>
                            {product.composition.map((item, index) => (
                              <div key={index}>
                                <span className="badge bg-primary">{item}</span>
                              </div>
                            ))}
                          </Col>
                        </Row>
                      </Col>
                      <Col md={6}>
                        <Row>
                          <Col md={6}>
                            <span className="f-w-600">Warehouse</span>
                          </Col>
                          <Col md={6}>
                            <span className="f-w-100">
                              {product.ware_house}
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      {/* <Col md={6}>
                        <Row>
                          <Col md={6}>
                            <span className="f-w-600">Group</span>
                          </Col>
                          <Col md={6}>
                            <span className="f-w-100">{product.group}</span>
                          </Col>
                        </Row>
                      </Col> */}
                      <Col md={6}>
                        <Row>
                          <Col md={6}>
                            <span className="f-w-600">COO</span>
                          </Col>
                          <Col md={6}>
                            <span className="f-w-100">{product.country}</span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mt-5">
                      <Col md={12}>
                        <div className="-100 table-container">
                          <Table className="shopping-cart-table">
                            <tbody>
                              <tr>
                                <td width="2%"></td>
                                <td
                                  className="fixed-column"
                                  style={{ minWidth: "275px" }}
                                ></td>

                                {colourVariants.map((item, i) => {
                                  return (
                                    <td key={i}>
                                      <div>{item.name}</div>
                                    </td>
                                  );
                                })}
                              </tr>
                              <tr>
                                <td
                                  rowSpan={3}
                                  className="vertical-text "
                                  style={{ padding: 0, background: "silver" }}
                                >
                                  Available
                                </td>
                                <td className="fixed-column">
                                  <span className="badge bg-success w-25">
                                    IMMEDIATE
                                  </span>
                                  {/* <span className="f-12"> FEB 01 2024- FEB 29 2024</span> */}
                                  <span className="f-12"> </span>
                                </td>
                                {colourVariants.map((item, i) => {
                                  return <td key={i}>{item.quantity}</td>;
                                })}
                              </tr>
                              <tr>
                                <td className="fixed-column">
                                  <span className="badge bg-warning  w-25">
                                    FUTURE
                                  </span>
                                  {/* <span className="f-12"> FEB 01 2024- FEB 29 2024</span> */}
                                  <span className="f-12">
                                    {" "}
                                    {product.future_delivery}
                                  </span>
                                </td>
                                {colourVariants.map((item, i) => {
                                  return (
                                    <td key={i}>{item.future_quantity}</td>
                                  );
                                })}
                                <td className="fixed-column-right"></td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </div>
          ) : (
            <PreLoader withoutText></PreLoader>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};
export default ProductView;

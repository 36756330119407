import React, { Fragment, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ModalHeader,
  Modal,
  ModalBody,
  Media,
  Table,
  Input,
  CustomInput,
} from "reactstrap";
import { handelBinTransferDialog } from "../../manage-product/store/inventorySlice";
import { useDispatch } from "react-redux";
import {
  getShoppingProductById,
  setProductData,
  setProductFilter,
  setProductViewDialog,
  setSettings,
} from "../store/shoppingSlice";
import Slider from "react-slick";
import ShoppingProductOrder from "./shoppingProductOrder";
import Select from "react-select";

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, left: "20px", zIndex: 1 }} // Adjust left position as needed
      onClick={onClick}
    >
      Previous
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, right: "20px", zIndex: 1 }} // Adjust right position as needed
      onClick={onClick}
    >
      Next
    </div>
  );
};

const settings = {
  dots: true,
  fade: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  waitForAnimate: false,
  prevArrow: <CustomPrevArrow />,
  nextArrow: <CustomNextArrow />,
};
const ColorOption = ({ color, code }) => {
  return (
    <div>
      <span
        style={{
          backgroundColor: color,
          width: "20px",
          height: "20px",
          display: "inline-block",
          marginRight: "5px",
        }}
      ></span>
      {code} - {color}
    </div>
  );
};
const ShoppingProductView = (props) => {
  const { shoppingState } = props;
  const dispatch = useDispatch();
  const closeModalDialog = () => {
    dispatch(setProductViewDialog({ props: { isOpen: false }, data: null }));
  };
  useEffect(() => {
    if (shoppingState.productViewDialogProps.props.isOpen) {
      dispatch(
        getShoppingProductById(shoppingState.productViewDialogProps.data.id)
      ).then((res) => {
        const payload = res.payload;
        if (!res.error) dispatch(setProductData(payload));
      });
    }
  }, [shoppingState.productViewDialogProps.props]);
  const product = shoppingState.product;

  return (
    <Fragment>
      <div className="flex flex-col modal-xl">
        <Modal
          className="fullscreen-modal"
          {...shoppingState.productViewDialogProps.props}
          toggle={() => closeModalDialog()}
        >
          <ModalBody>
            {product && (
              <Row>
                <Col md={4}>
                  <div className="slider-container">
                    {product.images && (
                      <Slider {...settings}>
                        {product.images.map((item, i) => {
                          return (
                            <div className="item" key={i}>
                              <div className="text-center ">
                                <Media
                                  src={item.image_path}
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          );
                        })}
                      </Slider>
                    )}
                  </div>
                </Col>
                <Col md={8}>
                  <Row>
                    <Col md={12}>
                      <a onClick={closeModalDialog} className="pull-right">
                        <i className="fa fa-close"></i>
                      </a>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Row>
                        <Col md={12}>
                          <span className="f-w-600 f-16">{product.name}</span>
                        </Col>
                        <Col md={12}>
                          <span className="f-w-100 f-12">{product.code}</span>
                        </Col>
                        <Col md={12}>
                          <span className="f-w-100 f-12">Selling Price : </span>{" "}
                          <span className="f-w-600 f-14">
                            $
                            {product.sale_price
                              ? product.sale_price
                              : product.price}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Select
                        options={product.colours}
                        value={product.selected_colour}
                        getOptionValue={(option) => option.id}
                        onChange={(e) => {
                          dispatch(
                            setProductData({
                              ...shoppingState.product,
                              selected_colour: e,
                            })
                          );
                        }}
                        getOptionLabel={(option) => (
                          <div>
                            <span
                              style={{
                                backgroundColor: option.code,
                                width: "20px",
                                height: "20px",
                                display: "inline-block",
                                marginRight: "5px",
                                marginTop: 5,
                              }}
                            ></span>
                            <span style={{ marginTop: "-10px" }}>
                              {" "}
                              {option.name}
                            </span>
                          </div>
                        )}
                      />
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col md={12}>
                      <p>{product.meta_description}</p>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={6}>
                      <Row>
                        <Col md={6}>
                          <span className="f-w-600">Brand</span>
                        </Col>
                        <Col md={6}>
                          <span className="f-w-100">{product.brand}</span>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col md={6}>
                          <span className="f-w-600">Type</span>
                        </Col>
                        <Col md={6}>
                          <span className="f-w-100">{product.type}</span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col md={6}>
                      <Row>
                        <Col md={6}>
                          <span className="f-w-600">Category</span>
                        </Col>
                        <Col md={6}>
                          <span className="f-w-100">{product.category}</span>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col md={6}>
                          <span className="f-w-600">Sub Category</span>
                        </Col>
                        <Col md={6}>
                          <span className="f-w-100">
                            {product.sub_category}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col md={6}>
                      <Row>
                        <Col md={6}>
                          <span className="f-w-600">Season</span>
                        </Col>
                        <Col md={6}>
                          <span className="f-w-100">{product.season}</span>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col md={6}>
                          <span className="f-w-600">Year</span>
                        </Col>
                        <Col md={6}>
                          <span className="f-w-100">{product.year}</span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col md={6}>
                      <Row>
                        <Col md={6}>
                          <span className="f-w-600">Composition</span>
                        </Col>
                        <Col md={6}>
                          {product.composition.map((item, index) => (
                            <div key={index}>
                              <span className="badge bg-primary">{item}</span>
                            </div>
                          ))}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col md={6}>
                          <span className="f-w-600">Warehouse</span>
                        </Col>
                        <Col md={6}>
                          <span className="f-w-100">{product.ware_house}</span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col md={6}>
                      <Row>
                        <Col md={6}>
                          <span className="f-w-600">COO</span>
                        </Col>
                        <Col md={6}>
                          <span className="f-w-100">{product.country}</span>
                        </Col>
                      </Row>
                    </Col>
                    {/* <Col md={6}>
                      <Row>
                        <Col md={6}>
                          <span className="f-w-600">Group</span>
                        </Col>
                        <Col md={6}>
                          <span className="f-w-100">{product.group}</span>
                        </Col>
                      </Row>
                    </Col> */}
                  </Row>
                  <ShoppingProductOrder
                    shoppingState={shoppingState}
                  ></ShoppingProductOrder>
                </Col>
              </Row>
            )}
          </ModalBody>
        </Modal>
      </div>
    </Fragment>
  );
};

export default ShoppingProductView;

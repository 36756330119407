import React, {Fragment, useState} from 'react';
import {Bell} from "react-feather";
import {CheckAllNotification, Notification} from "../../constant";
import Pusher from "pusher-js";
import {host} from "../../utils/api";
import {useDispatch} from "react-redux";
import {setNotification} from "../../redux/auth/userSlice";
import {notificationMarkAsRead} from "../../services/auth";
import {useHistory} from "react-router-dom";
import "../../components/notification/notofication.scss"
import {
    Col,
    ListGroup,
    ListGroupItem, Media,
    Row
} from "reactstrap";
import useSound from "use-sound";
import notificationSound from '../../assets/audio/notification.mp3';
import InitialsAvatar from 'react-initials-avatar';
import 'react-initials-avatar/lib/ReactInitialsAvatar.css';
import {logos} from "../../utils/common";

const pusherKeys=[
    {domain:"localhost",appKey:'c8813d7053d8e190fb8e'},
    {domain:"mp.laceandbeads.co.uk",appKey:'c8813d7053d8e190fb8e'},
    {domain:"mpqa.laceandbeads.co.uk",appKey:'c8813d7053d8e190fb8e'},

];


function DesktopNotification(props) {

    const {currentUser} = props;
    const dispatch = useDispatch();
    let history = useHistory();
    const [play] = useSound(notificationSound);
    const domainName = window.location.hostname;
    const pusherKey=pusherKeys.find(function (el) {

        return el.domain==domainName;
    })
    const [notificationReset, setNotificationReset] = useState(false)
    const [notificationDropDown, setNotificationDropDown] = useState(false)
    const token = localStorage.getItem('token');
    var pusher = new Pusher(pusherKey.appKey, {
        cluster: 'eu',
        auth: {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        },
        authEndpoint:host + 'broadcasting/auth'
    });
    Pusher.logToConsole = true;

    const domainJost = window.location.hostname == "localhost" ? 'mp.localhost' : `${window.location.hostname}`;
    var channel = pusher.subscribe('private-users-' + currentUser.user.id + '-' + domainJost);
    channel.bind('Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', function (data) {
        let notifcations = [];
        notifcations = [
            ...currentUser.user.notifications,
            data
        ];
        // play();
        // console.log(notifcations);
        dispatch(setNotification(notifcations))
    });

    let height = window.innerHeight;
    let x = 6;
    if (height===649 || height=== 213 || height ===334 || height ===653 || height ===681 || height ===150) {
        x = 4;
    }



    async function markAsRead(id, url) {
        await notificationMarkAsRead(id).then(res => {
            if (!res.errors) {
                let array = currentUser.user.notifications;
                const newArray = array.filter(item => item.id !== id)
                dispatch(setNotification(newArray))
            }
        });
        url ? history.push(url) : history.push('/dashboard')
    }

    function openAllNotifications() {
        history.push('/view-all-notifications')
    }

    return (
        <Fragment>
            <div className="notification-box" onClick={() => setNotificationDropDown(!notificationDropDown)} >
                <Bell/><span
                className="badge badge-pill badge-secondary">{(currentUser.user.notifications).length}</span></div>
            <ul className={`notification-dropdown onhover-show-div ${notificationDropDown ? "active" : ""}`}>
                <li>
                    <h6 className="f-18 mb-0">Notification</h6>
                </li>
                {currentUser.user.notifications.slice(0,x).map((item, index) =>
                    <li className="notification-list" key={index} >

                        <a className="f-12" onClick={(e) => {
                            markAsRead(item.id, item.route??'').then()
                        }} href="#">
                            <ListGroup style={{marginLeft: '-15px', marginRight: '-15px'}}>
                                <ListGroupItem style={{width: '100%'}}>
                                    <Row className="align-items-center">
                                        <Col md={2} className="pr-3">
                                            {item.image != '' ? <Media style={{height: 46, width:46}} className="rounded-circle" alt="user-image" src={item.image} /> : <InitialsAvatar name={item.name}/> }
                                        </Col>
                                        <Col md={10}>
                                            <Row className="align-items-center">
                                                <Col md={12} className="text-left ml-3">
                                                    <span style={{fontWeight: "normal", color:'#0e0d0d', fontSize: 14}} className="text-black">{item.message}</span>
                                                </Col>
                                                <Col md={6} className="text-left">
                                                    <span style={{color: '#b919bd', marginLeft:17}}>{item.name}</span>
                                                </Col>
                                                <Col md={6}>
                                                    <span style={{color: '#b919bd'}}>{item.time}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </ListGroupItem>
                            </ListGroup>
                        </a>
                    </li>
                )}

                <li>
                    <button onClick={() => openAllNotifications()}
                            className="btn btn-primary">{CheckAllNotification}</button>
                </li>
            </ul>

        </Fragment>
    );
}

function areEqual(prevProps, nextProps) {
    return JSON.stringify(prevProps.currentUser) == JSON.stringify(nextProps.currentUser);
    /*
    return true if passing nextProps to render would return
    the same result as passing prevProps to render,
    otherwise return false
    */
}

export default React.memo(DesktopNotification, areEqual);
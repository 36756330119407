import React, {Fragment, useEffect, useRef, useState} from "react";
import {
    Badge,
    Card,
    CardBody,
    Col,
    FormGroup,
    Input,
    Label,
} from "reactstrap";
import Select from "react-select";

import DatePicker from "react-datepicker";
import {InputTags} from "react-bootstrap-tagsinput";
import moment from "moment";

import {useDispatch} from "react-redux";
import {
    getSubCategories,
    setProductSettings,
    setProductSubCategory,
} from "../../../store/prodcutSlice";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import AsyncSelect from "react-select/async";
import {
    mapIdSearchAutoSuggestion,
    mapSearchAutoSuggestion,
} from "../../../../../utils/mapper";
import {taxonomySuggestionApi} from "../../../../../services/manage-product/productService";
import SunRichEditor from "../../../../../share-components/ui/sunRichEditor";
import ProductInfo from "./productInfo";
import CustomTagsInput from "../../../../../share-components/ui/customTagsInput";

const editorConfiguration = {
    toolbar: [
        "bold",
        "italic",
        "bulletedList",
        "numberedList",
        "fontColor",
        "fontBackgroundColor",
    ],
};

const General = (props) => {
    const {
        errors,
        register,
        setValue,
        trigger,
        getValues,
        productState,
        currentUser,
        productStates,
    } = props;
    const [tags, setTags] = useState([]);
    let styleRef = useRef([]);
    const dispatch = useDispatch();

    const [val, setVal] = useState("");
    const combineTags = () => {
        let tag = [];
        tag.push(getValues("product_type").name);

        if (getValues("product_category")) {
            tag.push(getValues("product_category").name);
        }
        if (getValues("product_sub_category")) {
            tag.push(getValues("product_sub_category").name);
        }
        if (getValues("country")) {
            tag.push(getValues("country").name);
        }
        if (getValues("season")) {
            tag.push(getValues("season").name);
        }
        if (getValues("product_group")) {
            tag.push(getValues("product_group").name);
        }
        getValues("tags").map((item, i) => {
            tag.push(item.name);
        });
        getValues("styles").map((item, i) => {
            tag.push(item.name);
        });
        getValues("custom_tags").map((item, i) => {
            tag.push(item);
        });
        setTags(tag);
    };
    useEffect(() => {
        combineTags();
        if (!getValues("meta_description")) {
            setMetaDescription();
        }
        if (!getValues("meta_title")) {
            setMetaTitle();
        }
    }, []);

    const nameAvailableCharacters = () => {
        const brandLength = brandLengthCharacters();
        const nameLength = getValues("name").length;
        return brandLength + nameLength;
    };
    const brandLengthCharacters = () => {
        return (getValues("brand")?.name).length;
    };
    const setMetaTitle = () => {
        const word = currentUser.modules.includes("is-meta-whole-sale")
            ? "Wholesale"
            : getValues("brand")?.name;
        setValue("meta_title", word + " " + getValues("name"));
        trigger("meta_title");
    };
    const setMetaDescription = () => {
        const regex = /<[^>]+>/g;
        const newString = getValues("description")
            ? getValues("description").replace(regex, "").substring(0, 160)
            : "";
        const str = newString.replace(/&nbsp;/g, "");

        const final = str.toString().trimStart();

        setValue("meta_description", final);
        trigger("meta_description");
    };
    //const roleRegister = register("role", { required: true });
    const typePromiseOption = (inputValue) =>
        new Promise((resolve) => {
            const data = {
                value: inputValue,
            };
            setTimeout(() => {
                resolve(mapIdSearchAutoSuggestion(taxonomySuggestionApi(data)));
            });
        });

    return (
        <Fragment>
            <Card>
                <CardBody>
                    <div className="form-row mb-3">
                        <Col md="3">
                            <FormGroup>
                                <Label className="col-form-label">Brand</Label>
                                <Select
                                    getOptionLabel={(option) => option.name}
                                    menuPortalTarget={document.body}
                                    styles={{menuPortal: (base) => ({...base, zIndex: 100})}}
                                    defaultValue={getValues("brand")}
                                    getOptionValue={(option) => option.id}
                                    {...register("brand")}
                                    onChange={(e) => {
                                        setValue("brand", e);
                                        setMetaTitle();
                                        trigger("brand");
                                    }}
                                    className={` ${errors.brand ? "is-invalid" : ""}`}
                                    options={productState.brands}
                                />

                                <div className="invalid-feedback">{errors.brand?.message}</div>
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Label className="col-form-label">Product Title </Label>
                                <input
                                    name="name"
                                    type="text"
                                    {...register("name")}
                                    onKeyDown={(e) => {
                                        trigger("name");
                                    }}
                                    onBlur={(e) => {
                                        setMetaTitle();
                                    }}
                                    className={`form-control ${errors.name ? "is-invalid" : ""}`}
                                />
                                <div className="invalid-feedback">{errors.name?.message}</div>
                            </FormGroup>
                        </Col>
                        <Col md="3">
                            <FormGroup>
                                <Label className="col-form-label">Style Number</Label>
                                <input
                                    name="code"
                                    type="text"
                                    {...register("code")}
                                    className={`form-control ${errors.code ? "is-invalid" : ""}`}
                                />
                                <div className="invalid-feedback">{errors.code?.message}</div>
                            </FormGroup>
                        </Col>
                    </div>
                    <div className="form-row mb-3">
                        <Col md="12">
                            <Label className="col-form-label">Product Description</Label>
                            <SunRichEditor
                                height={200}
                                defaultValue={getValues("description")}
                                onChange={(content) => {
                                    setValue("description", content);
                                    setMetaDescription();
                                }}
                            ></SunRichEditor>

                            <div className="invalid-feedback"></div>
                        </Col>
                    </div>
                    <div className="form-row mb-3">
                        <Col md="12">
                            <Label className="col-form-label">Product Summary</Label>
                            <SunRichEditor
                                height={150}
                                defaultValue={getValues("summary")}
                                onChange={(content) => {
                                    setValue("summary", content);
                                }}
                            ></SunRichEditor>
                            <div className="invalid-feedback"></div>
                        </Col>
                    </div>
                    {/*<div className="form-row mb-3">*/}
                    {/*    <Col md="12">*/}
                    {/*        <Label className="col-form-label">Product measurements/conversion to UK sizing if*/}
                    {/*            applicable</Label>*/}
                    {/*        <input name="measurements" type="text" {...register('measurements')}*/}
                    {/*               className={`form-control ${errors.measurements ? 'is-invalid' : ''}`}/>*/}
                    {/*        <div className="invalid-feedback">{errors.measurements?.message}</div>*/}
                    {/*    </Col>*/}
                    {/*</div>*/}
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <div className="form-row mb-3">
                        <Col md="4">
                            <FormGroup>
                                <Label className="col-form-label">Product Type</Label>
                                <Select
                                    getOptionLabel={(option) => option.name}
                                    menuPortalTarget={document.body}
                                    styles={{menuPortal: (base) => ({...base, zIndex: 100})}}
                                    defaultValue={getValues("product_type")}
                                    getOptionValue={(option) => option.id}
                                    {...register("product_type")}
                                    onChange={(e) => {
                                        setValue("product_type", e);
                                        trigger("product_type");
                                        combineTags();
                                    }}
                                    className={` ${errors.product_type ? "is-invalid" : ""}`}
                                    options={productState.types}
                                />

                                <div className="invalid-feedback">
                                    {errors.product_type?.message}
                                </div>
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label className="col-form-label">
                                    Product Category{" "}
                                    {/* <a
                    className="a-text-color"
                    target="_blank"
                    href="https://storage.googleapis.com/city_goddess_market_place/upload/style/category-guide.pdf"
                  >
                    (CG category guide)
                  </a> */}
                                </Label>
                                <Select
                                    getOptionLabel={(option) => option.name}
                                    menuPortalTarget={document.body}
                                    styles={{menuPortal: (base) => ({...base, zIndex: 100})}}
                                    defaultValue={getValues("product_category")}
                                    getOptionValue={(option) => option.id}
                                    {...register("product_category")}
                                    onChange={(e) => {
                                        setValue("product_category", e);
                                        trigger("product_category");
                                        combineTags();
                                        dispatch(getSubCategories(e.id)).then((res) => {
                                            dispatch(setProductSubCategory(res.payload));
                                        });
                                    }}
                                    className={` ${errors.product_category ? "is-invalid" : ""}`}
                                    options={productState.categories}
                                />

                                <div className="invalid-feedback">
                                    {errors.product_category?.message}
                                </div>
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label className="col-form-label">Product Sub Category</Label>
                                <Select
                                    getOptionLabel={(option) => option.name}
                                    menuPortalTarget={document.body}
                                    styles={{menuPortal: (base) => ({...base, zIndex: 100})}}
                                    defaultValue={getValues("product_sub_category")}
                                    getOptionValue={(option) => option.id}
                                    {...register("product_sub_category")}
                                    onChange={(e) => {
                                        setValue("product_sub_category", e);
                                        setValue("styles", []);
                                        trigger("product_sub_category");

                                        combineTags();
                                    }}
                                    className={` ${
                                        errors.product_sub_category ? "is-invalid" : ""
                                    }`}
                                    options={productState.sub_categories}
                                />

                                <div className="invalid-feedback">
                                    {errors.product_sub_category?.message}
                                </div>
                            </FormGroup>
                        </Col>
                        {/* <Col md="3">
                            <FormGroup>
                                <Label className="col-form-label">Product Group</Label>
                                <Select
                                    getOptionLabel={(option) => option.name}
                                    menuPortalTarget={document.body}
                                    styles={{menuPortal: (base) => ({...base, zIndex: 100})}}
                                    defaultValue={getValues("product_group")}
                                    getOptionValue={(option) => option.id}
                                    {...register("product_group")}
                                    onChange={(e) => {
                                        setValue("product_group", e);
                                        trigger("product_group");
                                        combineTags();
                                    }}
                                    className={` ${errors.product_group ? "is-invalid" : ""}`}
                                    options={productState.product_groups}
                                />

                                <div className="invalid-feedback">
                                    {errors.product_group?.message}
                                </div>
                            </FormGroup>
                        </Col> */}
                    </div>

                    <div className="form-row mb-3">
                        <Col md="5">
                            <div className="form-row ">
                                <Col md="3">
                                    <FormGroup>
                                        <Label className="col-form-label">Tag Type</Label>
                                        <Select
                                            getOptionLabel={(option) => option.name}
                                            menuPortalTarget={document.body}
                                            styles={{
                                                menuPortal: (base) => ({...base, zIndex: 100}),
                                            }}
                                            defaultValue={getValues("tag_type")}
                                            getOptionValue={(option) => option.id}
                                            {...register("tag_type")}
                                            onChange={(e) => {
                                                setValue("tag_type", e);
                                                trigger("tag_type");
                                            }}
                                            className={` ${errors.tag_type ? "is-invalid" : ""}`}
                                            options={productState.tag_settings}
                                        />

                                        <div className="invalid-feedback">
                                            {errors.tag_type?.message}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="9">
                                    <FormGroup>
                                        <Label className="col-form-label">Product Tags</Label>
                                        <Select
                                            getOptionLabel={(option) => option.name}
                                            menuPortalTarget={document.body}
                                            styles={{
                                                menuPortal: (base) => ({...base, zIndex: 100}),
                                            }}
                                            defaultValue={getValues("tags")}
                                            isMulti
                                            getOptionValue={(option) => option.id}
                                            {...register("tags")}
                                            onChange={(e) => {
                                                setValue("tags", e);
                                                trigger("tags");
                                                combineTags();
                                            }}
                                            className={` ${errors.tags ? "is-invalid" : ""}`}
                                            options={productState.tags}
                                        />

                                        <div className="invalid-feedback">
                                            {errors.tags?.message}
                                        </div>
                                    </FormGroup>
                                </Col>
                            </div>
                        </Col>
                        <Col md="3">
                            <Label className="col-form-label">Related Product Handle</Label>
                            <div>
                                <div className="input-group">
                                    <CustomTagsInput
                                        value={getValues("custom_tags")}
                                        tagChangeEvent={(value) => {
                                            setValue("custom_tags", value);
                                            combineTags();
                                        }}
                                    ></CustomTagsInput>
                                </div>
                            </div>
                            <div className="invalid-feedback">
                                {errors.custom_tags?.message}
                            </div>
                        </Col>
                        {/* <Col md="3">
              <FormGroup>
                <Label className="col-form-label">Standard Product Type</Label>

                <AsyncSelect
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 100 }) }}
                  isClearable
                  cacheOptions
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  defaultOptions
                  {...register("taxonomy")}
                  defaultValue={getValues("taxonomy")}
                  loadOptions={typePromiseOption}
                  onChange={(e) => {
                    setValue("taxonomy", e);
                    trigger("taxonomy");
                  }}
                />
                <div className="invalid-feedback">
                  {errors.taxonomy?.message}
                </div>
              </FormGroup>
            </Col> */}
                        <Col md="2">
                            <FormGroup>
                                <Label className="col-form-label">Season</Label>
                                <Select
                                    getOptionLabel={(option) => option.name}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: (base) => ({...base, zIndex: 100}),
                                    }}
                                    defaultValue={getValues("season")}
                                    getOptionValue={(option) => option.id}
                                    {...register("season")}
                                    onChange={(e) => {
                                        setValue("season", e);
                                        trigger("season");
                                        combineTags();
                                    }}
                                    className={` ${errors.season ? "is-invalid" : ""}`}
                                    options={productState.seasons}
                                />

                                <div className="invalid-feedback">{errors.season?.message}</div>
                            </FormGroup>
                        </Col>
                        <Col md="2">
                          <Label className="col-form-label">Year</Label>
                            <FormGroup>
                                <DatePicker
                                    name="manufactured_year"
                                    className={`form-control digits ${
                                        errors.manufactured_year ? "is-invalid" : ""
                                    }`}
                                    selected={
                                        getValues('manufactured_year') ? new Date(getValues('manufactured_year')) : ""
                                    }
                                    onChange={(date) => {
                                        setValue('manufactured_year', moment(date).format('YYYY'));
                                        trigger('manufactured_year');
                                    }}
                                    showYearPicker
                                    style={{minHeight: "auto"}}
                                    dateFormat="yyyy"
                                />
                            </FormGroup>
                        </Col>
                    </div>
                    <div className="form-row mb-3"></div>
                    <div className="form-row mb-3">
                        <Col md="12">
                            <Label className="col-form-label">Styles</Label>
                            <div>
                <span>
                  Selecting styles that apply to your product makes it easier
                  for customers to find it.{" "}
                    {currentUser.role.id != 1 ? "Please select up to 3." : ""}
                </span>
                            </div>
                            <div className="mt-3">
                                <Col md="12">
                                    <div className="form-row mb-3">
                                        {productState.styles
                                            .filter(function (value, index) {
                                                return (
                                                    value.category.filter(function (v, i) {
                                                        return (
                                                            v.id == getValues("product_sub_category")?.id
                                                        );
                                                    }).length > 0
                                                );
                                            })
                                            .map((item, i) => (
                                                <Col md="3" key={item.id}>
                                                    <div className=" ">
                                                        <input
                                                            id={`inline-${i}`}
                                                            type="checkbox"
                                                            ref={(ref) => {
                                                                styleRef.current[i] = ref;
                                                            }}
                                                            value={JSON.stringify(item)}
                                                            onChange={(e) => {
                                                                const isLimit =
                                                                    currentUser.role.id != 1
                                                                        ? getValues("styles").length < 3
                                                                        : true;

                                                                let currentStyle = JSON.parse(e.target.value);
                                                                let styles = getValues("styles");
                                                                let styleIndex = styles.findIndex(
                                                                    (x) => x.id === currentStyle.id
                                                                );
                                                                if (styleIndex != "-1") {
                                                                    if (!e.target.checked) {
                                                                        styles.splice(styleIndex, 1);
                                                                    }
                                                                } else {
                                                                    if (isLimit) {
                                                                        styles.push({
                                                                            id: currentStyle.id,
                                                                            name: currentStyle.name,
                                                                        });
                                                                    } else {
                                                                        styleRef.current[i].checked = false;
                                                                    }
                                                                }
                                                                setValue("styles", styles);
                                                                combineTags();
                                                            }}
                                                            defaultChecked={
                                                                getValues("styles").findIndex(
                                                                    (x) => x.id === item.id
                                                                ) != -1
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        <Label for={`inline-${i}`} className="ml-2">
                                                            {item.name}
                                                        </Label>
                                                    </div>
                                                </Col>
                                            ))}
                                    </div>
                                </Col>
                            </div>
                        </Col>
                    </div>
                    <div className="form-row mb-3">
                        <Col md="12">
                            <Label className="col-form-label">Tags</Label>
                            <div>
                                {tags.map((item, i) => (
                                    <Badge color="primary" key={i}>
                                        {item}
                                    </Badge>
                                ))}
                            </div>
                        </Col>
                    </div>
                </CardBody>
            </Card>
            {/* {getValues("product_category") &&
                getValues("product_category").dress_configuration == 1 && (
                    <Card>
                        <CardBody>
                            <div className="form-row mb-3">
                                <Col md="6">
                                    <FormGroup>
                                        <Label className="col-form-label">Model Height</Label>
                                        <input
                                            name="model_height"
                                            type="text"
                                            {...register("model_height")}
                                            className={`form-control ${
                                                errors.model_height ? "is-invalid" : ""
                                            }`}
                                        />
                                        <div className="invalid-feedback">
                                            {errors.model_height?.model_height}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <Label className="col-form-label">Model wear Size</Label>
                                        <Select
                                            getOptionLabel={(option) => option.name}
                                            defaultValue={getValues("model_ware_size")}
                                            getOptionValue={(option) => option.id}
                                            {...register("model_ware_size")}
                                            onChange={(e) => {
                                                setValue("model_ware_size", e);
                                                trigger("model_ware_size");
                                                // combineTags();
                                            }}
                                            className={` ${errors.country ? "is-invalid" : ""}`}
                                            options={productState.size_matrix}
                                        />
                                        <div className="invalid-feedback">
                                            {errors.model_ware_size?.model_ware_size}
                                        </div>
                                    </FormGroup>
                                </Col>
                            </div>
                            <div className="form-row mb-3">
                                <Col md="6">
                                    <FormGroup>
                                        <Label className="col-form-label">About the Fit</Label>
                                        <Select
                                            getOptionLabel={(option) => option.name}
                                            defaultValue={getValues("fit_type")}
                                            isMulti
                                            getOptionValue={(option) => option.id}
                                            {...register("fit_type")}
                                            onChange={(e) => {
                                                setValue("fit_type", e);
                                                trigger("fit_type");
                                            }}
                                            className={` ${errors.tags ? "is-invalid" : ""}`}
                                            options={productState.fit_types}
                                        />

                                        <div className="invalid-feedback">
                                            {errors.tags?.message}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <Label className="col-form-label">
                                            Whether true to size
                                        </Label>
                                        <Select
                                            getOptionLabel={(option) => option.name}
                                            defaultValue={getValues("fit_size_condition")}
                                            isMulti
                                            getOptionValue={(option) => option.id}
                                            {...register("fit_size_condition")}
                                            onChange={(e) => {
                                                setValue("fit_size_condition", e);
                                                trigger("fit_size_condition");
                                            }}
                                            className={` ${
                                                errors.fit_type_condition ? "is-invalid" : ""
                                            }`}
                                            options={productState.fit_size_condition}
                                        />

                                        <div className="invalid-feedback">
                                            {errors.fit_size_condition?.message}
                                        </div>
                                    </FormGroup>
                                </Col>
                            </div>
                        </CardBody>
                    </Card>
                )} */}
            {/* <Card>
        <CardBody>
          <div className="ribbon ribbon-clip ribbon-success ribbon-left mb-5">
            Pre Order
          </div>
          <div className="form-row mb-3">
            <Col md="6">
              <Label className="col-form-label">
                Is this a pre-order product?
              </Label>
              <Col md="12">
                <div className="checkbox checkbox-dark">
                  <Input
                    id="is_pre_order"
                    type="checkbox"
                    onChange={(e) => {
                      setValue("is_pre_order", e.target.checked);
                      trigger("is_pre_order");
                    }}
                    defaultChecked={getValues("is_pre_order")}
                  />
                  <Label for="is_pre_order">
                    Let customers preorder your product
                  </Label>
                </div>
              </Col>
            </Col>
            {getValues("is_pre_order") && (
              <Col md="4">
                <FormGroup>
                  <Label className="col-form-label">Dispatch Date</Label>
                  <div style={{ display: "flex" }}>
                    <div className="checkbox checkbox-dark">
                      <Input
                        id="is_dispatch"
                        type="checkbox"
                        onChange={(e) => {
                          setValue("is_dispatch_date", e.target.checked);
                          setValue("dispatch_date", "");
                          setValue("no_of_days", "");

                          trigger("is_dispatch_date");
                        }}
                        defaultChecked={getValues("is_dispatch_date")}
                      />
                      <Label for="is_dispatch"></Label>
                    </div>
                    <DatePicker
                      name="dispatchDate"
                      className={`form-control digits ${
                        errors.dispatch_date ? "is-invalid" : ""
                      }`}
                      selected={
                        getValues("dispatch_date")
                          ? new Date(getValues("dispatch_date"))
                          : null
                      }
                      onChange={(date) => {
                        setValue("dispatch_date", date);
                        trigger("dispatch_date");
                      }}
                      minDate={moment().toDate()}
                      readOnly={!getValues("is_dispatch_date")}
                      style={{ minHeight: "auto" }}
                      dateFormat="dd-MM-yyyy"
                    />
                  </div>
                  <div className="invalid-feedback">
                    {errors.dispatch_date?.message}
                  </div>
                </FormGroup>
              </Col>
            )}
            {getValues("is_pre_order") && (
              <Col md="2">
                <FormGroup>
                  <Label className="col-form-label">No Of Days</Label>
                  <input
                    disabled={getValues("is_dispatch_date")}
                    name="no_of_days"
                    type="text"
                    {...register("no_of_days")}
                    className={`form-control ${
                      errors.no_of_days ? "is-invalid" : ""
                    }`}
                  />
                  <div className="invalid-feedback">
                    {errors.no_of_days?.message}
                  </div>
                </FormGroup>
              </Col>
            )}
          </div>
        </CardBody>
      </Card> */}
            {/* <Card>
                <CardBody>
                    <div className="ribbon ribbon-clip ribbon-info ribbon-left mb-5">
                        Holiday Mode
                    </div>
                    <div className="form-row mb-3">
                        <Col md="6">
                            <Label className="col-form-label">
                                Do you want to set holiday mode?
                            </Label>
                            <Col md="12">
                                <div className="checkbox checkbox-dark">
                                    <Input
                                        id="is_holiday_mode"
                                        type="checkbox"
                                        onChange={(e) => {
                                            setValue("is_holiday_mode", e.target.checked);
                                            trigger("is_holiday_mode");
                                        }}
                                        defaultChecked={getValues("is_holiday_mode")}
                                    />
                                    <Label for="is_holiday_mode">Holiday Mode</Label>
                                </div>
                            </Col>
                        </Col>
                        {getValues("is_holiday_mode") && (
                            <Col md="4">
                                <FormGroup>
                                    <Label className="col-form-label">End Date</Label>
                                    <div style={{display: "flex"}}>
                                        <DatePicker
                                            name="holidayEndDate"
                                            className={`form-control digits ${
                                                errors.holiday_end_date ? "is-invalid" : ""
                                            }`}
                                            selected={
                                                getValues("holiday_end_date")
                                                    ? new Date(getValues("holiday_end_date"))
                                                    : null
                                            }
                                            onChange={(date) => {
                                                setValue("holiday_end_date", date);
                                                trigger("holiday_end_date");
                                            }}
                                            minDate={moment().toDate()}
                                            style={{minHeight: "auto"}}
                                            dateFormat="dd-MM-yyyy"
                                        />
                                    </div>
                                    <div
                                        className="invalid-feedback"
                                        style={{display: "block"}}
                                    >
                                        {errors.holiday_end_date?.message}
                                    </div>
                                </FormGroup>
                            </Col>
                        )}
                    </div>
                </CardBody>
            </Card> */}
        </Fragment>
    );
};

export default General;

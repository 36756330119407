import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import { Card, CardBody, Col, FormGroup, Row } from "reactstrap";

const PublishProduct = ({ setValue, getValues, register }) => {
  const [isShopify, setIsShopify] = useState(false);
  const [isWholesale, setIsWholesale] = useState(false);

  useEffect(() => {
    register("is_shopify");
    register("is_wholesale");

    setIsShopify(!!getValues("is_shopify"));
    setIsWholesale(!!getValues("is_wholesale"));
  }, [register, getValues]);

  const handleShopify = (checked) => {
    setIsShopify(checked);
    setValue("is_shopify", checked);
  };

  const handleWholesale = (checked) => {
    setIsWholesale(checked);
    setValue("is_wholesale", checked);
  };

  return (
    <Card>
      <CardBody>
        <div className="ribbon ribbon-clip ribbon-success ribbon-left mb-5">
          Publish
        </div>
        <Col md="4">
          <Row style={{ marginBottom: 15 }}>
            <Col md="6">
              <label style={{ marginTop: 8, marginLeft: 4 }}>Shopify</label>
              <FormGroup style={{ marginTop: 1, marginLeft: 4 }}>
                <Switch
                  checked={isShopify}
                  onChange={handleShopify}
                  className="react-switch"
                  uncheckedIcon={false}
                  checkedIcon={false}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <label style={{ marginTop: 8, marginLeft: 4 }}>Wholesale</label>
              <FormGroup style={{ marginTop: 1, marginLeft: 4 }}>
                <Switch
                  checked={isWholesale}
                  onChange={handleWholesale}
                  className="react-switch"
                  uncheckedIcon={false}
                  checkedIcon={false}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </CardBody>
    </Card>
  );
};

function areEqual(prevProps, nextProps) {
  return (
    JSON.stringify(prevProps.getValues()) ===
    JSON.stringify(nextProps.getValues())
  );
}

export default React.memo(PublishProduct, areEqual);

import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
  FormGroup,
  Input,
  Label,
  Popover,
  PopoverBody,
  PopoverHeader,
  Row,
} from "reactstrap";

import { Link, useHistory } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import { CarouseNavigation } from "../../../../../utils/common";
import { BillingDetails, NotFoundData } from "../../../../../constant";
import BillingOrderDetail from "../orderDetail";
import withReducer from "../../../../../store/withReducer";
import salesOrderInvoiceReducer from "../../../store";
import {
  getCountryList,
  getCountryListData,
  getDraftOrder,
} from "../../../store/draftOrderSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  addSalesOrderInvoice,
  editSaleOrderInvoice,
  getCreateInvoiceData,
  getInvoiceByOrder,
  getPendingSaleOrderInvoiceItems,
  setInvoiceFromSettings,
  setOrderInvoices,
  updateSalesOrderInvoice,
  downloadSalesOrderXeroInvoicePdf,
  downloadInvoiceRMAPdf,
  confirmSalesOrderInvoice,
} from "../../../store/saleOrderInvoiceSlice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import SaleOrderProductInvoiceJqx from "./content/saleOrderProductInvoicejqx";
import SearchProductModal from "../../../draft-order/form/content/searchProductModal";
import OrderDiscount from "../../../draft-order/form/content/orderDiscount";
import OrderShipment from "../../../draft-order/form/content/orderShipment";
import OrderTax from "../../../draft-order/form/content/orderTax";
import SalesOrderDuty from "../../create/content/salesOrderDuty";
import SaleOrderPayment from "../../create/content/saleOrderPayment";
import {
  addSalesOrders,
  setOrderData,
  sendSalesOrderInvoiceToXero,
  downloadSalesOrderInvoicePdf,
  sendSalesOrderInvoiceToQuickBook,
} from "../../../store/salesOrderSlice";
import LogActivity from "../../../../log";
import {
  getCreateData,
  setFromSettings,
} from "../../../store/saleOrderFulfillmentSlice";
import Select from "react-select";
import SalesOrderInvoiceView from "./view";
import ButtonLoader from "../../../../../share-components/ui/buttonLoader";
import AsyncSelect from "react-select/async";
import {
  mapSearchCustomerAutoSuggestion,
  mapSearchPaymentMethodAutoSuggestion,
} from "../../../../../utils/mapper";
import { customerAutoSuggestionApi } from "../../../../../services/order";
import { invoiceAutoSuggestionApi } from "../../../../../services/manage-order/saleOrderInvoiceService";
import SweetAlert from "sweetalert2";
import { addReturnItems } from "../../../store/orderSlice";
import { getPaymentAutoSuggestionApi } from "../../../../../services/manage-order/setting/paymentMethodService";
import PickUpDetail from "./content/pickUpDetail";
import OrderInvoiceItemDiscount from "../../../draft-order/form/content/invoiceItemDiscount";

const settings = {
  dots: false,
  infinite: false,
  slidesToShow: 5,
  slidesToScroll: 1,
  nextArrow: <CarouseNavigation icon="fa-arrow-right" />,
  prevArrow: <CarouseNavigation icon="fa-arrow-left" />,
};
const defaultValues = {
  id: "",
  ordered_products: [],
  due_date: "",
  tags: [],
  duty: "",
  terms: "",
  discount: "",
  shipping: "",
  tax: "",
  xero_id: "",
  total_net_weight: "",
  total_gross_weight: "",
  total_no_of_boxes: "",
  bank: "",
  is_weight_added: "",
  trade_option: "",
  net_unit_type: 1,
  gross_unit_type: 1,
  merge_invoices: [],
  is_merge: false,
  is_tax: false,
  return_request_items: [],
  payment_method: "",
};
const schema = yup.object().shape({
  shipping: yup
    .object()
    .shape({ rate_name: yup.string() })
    .required("shipment is required")
    .typeError("shipment is required"),
  total_gross_weight: yup
    .string()
    .required("You must enter total gross weight"),
  total_net_weight: yup.string().required("You must enter total net weight"),
  total_no_of_boxes: yup.string().required("You must enter total no of boxes"),
  terms: yup
    .object()
    .shape({ value: yup.string() })
    .nullable()
    .required("You must select a term."),
});
const BillIngInvoice = (props) => {
  const { id, order_id } = props;
  let history = useHistory();
  const dispatch = useDispatch();
  const draftOrderState = useSelector(({ order }) => order.draftOrder);
  const salesOrderInvoiceState = useSelector(
    ({ order }) => order.salesOrderInvoice
  );
  const salesOrderState = useSelector(({ order }) => order.salesOrder);
  const [isEditable, setIsEditable] = useState(!id);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [xeroButtonDisable, setIsXeroButtonDisable] = useState(false);
  const authData = useSelector(({ authReducer }) => authReducer);
  const [dropdownStatusOpen, setDropdownStatusOpen] = useState(false);
  const [dropdownRMAOpen, setDropdownRMAOpen] = useState(false);
  const [isProductValueChange, setIsProductValueChange] = useState(true);
  const [showBankDropdown, setShowBankDropdown] = useState(false);
  const [isDiscountValueChange, setIsDiscountValueChange] = useState(true);
  const [showReturn, setShowReturn] = useState(false);

  const toggleStatusDropdown = () => {
    setDropdownStatusOpen(!dropdownStatusOpen);
  };

  const toggleRMADropdown = () => {
    setDropdownRMAOpen(!dropdownRMAOpen);
  };

  const {
    handleSubmit,
    formState,
    reset,
    control,
    register,
    setValue,
    trigger,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { errors, isDirty } = formState;
  useEffect(() => {
    if (order_id) {
      const data = {
        id: order_id,
        params: {
          is_check_xero: 0,
        },
      };
      dispatch(getInvoiceByOrder(data)).then((res) => {
        dispatch(setOrderInvoices(res.payload));
      });
    }

    if (order_id && id) {
      dispatch(editSaleOrderInvoice(id)).then((res) => {
        if (!res.error) {
          dispatch(setOrderData(res.payload.order));
          reset(res.payload.invoice);
          setIsProductValueChange(!isProductValueChange);
          setIsDiscountValueChange(!isDiscountValueChange);

          setShowReturn(res.payload.invoice?.is_confirmed);
        }
      });
    } else {
      if (order_id) {
        dispatch(getPendingSaleOrderInvoiceItems(order_id)).then((res) => {
          if (!res.error) {
            dispatch(setOrderData(res.payload.order));
            setValue("ordered_products", res.payload.order_products);
            trigger("ordered_products");
            setIsProductValueChange(!isProductValueChange);
            setIsDiscountValueChange(!isDiscountValueChange);
          }
        });
      }
    }
  }, [id, order_id]);
  useEffect(() => {
    dispatch(getCreateInvoiceData()).then((res) => {
      dispatch(setInvoiceFromSettings(res.payload));
    });
    dispatch(getCountryList()).then((res) => {
      dispatch(getCountryListData(res.payload));
    });
  }, [dispatch]);

  const typePromiseOption = (inputValue) =>
    new Promise((resolve) => {
      const data = {
        value: inputValue,
        invoice_id: id,
        unit_type: getValues("net_unit_type"),
      };
      setTimeout(() => {
        resolve(
          mapSearchCustomerAutoSuggestion(invoiceAutoSuggestionApi(data))
        );
      });
    });

  const onSubmit = (data) => {
    setButtonDisable(true);
    const invoiceData = {
      sales_order_id: order_id,
      ...data,
      terms: data.terms.id,
      bank: data.bank?.id,
      payment_method: data.payment_method?.value,
      trade_option: data.trade_option?.id,
      merge_invoices: data.merge_invoices.map((num) => num.value),
      ordered_products: data.ordered_products
        .filter(function (v, i) {
          return v.is_selected;
        })
        .map(function (item, index) {
          return {
            id: item.id,
            sales_order_line_item_id: item.sales_order_line_item_id,
            quantity: item.quantity,
            price: item.rate,
            sub_total: item.total_price,
            tax_amount: item.tax_amount,
            total_amount: item.gross_price,
          };
        }),
    };

    if (id) {
      invoiceData.id = id;
      dispatch(updateSalesOrderInvoice({ ...invoiceData })).then((res) => {
        setIsEditable(!isEditable);
        setValue("is_weight_added", true);

        setButtonDisable(false);
      });
    } else {
      dispatch(addSalesOrderInvoice({ ...invoiceData })).then((res) => {
        if (!res.error) {
          setButtonDisable(false);
          history.push(
            `/order/sale/${order_id}/billing/invoice/${res.payload.id}`
          );
        }
      });
    }
  };

  const sendToXero = () => {
    SweetAlert.fire({
      title: "Are you sure want to send this invoice to Quick Books?",
      text: "Once confirmed, no changes will be accepted in this invoice",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Proceed",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setIsXeroButtonDisable(true);
        const xeroData = {
          invoice_id: id,
        };
        dispatch(sendSalesOrderInvoiceToQuickBook({ ...xeroData })).then(
          (res) => {
            if (!res.error) {
              setValue("xero_id", res.payload.xero_id);
              setIsXeroButtonDisable(false);
            } else {
              console.error("Error updating status:", res.error);
            }
          }
        );
      }
    });
  };

  const processPDFDownload = (name, isCommercial) => {
    const data = {
      id: id,
      params: {
        is_commercial: isCommercial,
      },
    };
    dispatch(downloadSalesOrderInvoicePdf(data)).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${getValues("invoice_no")}_${name.replace(/\s+/g, "-")}_${new Date()
          .toJSON()
          .slice(0, 10)}.pdf`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  const processRMAPDFDownload = (name, id) => {
    const data = {
      id: id,
    };
    dispatch(downloadInvoiceRMAPdf(data)).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${getValues("invoice_no")}_${name.replace(/\s+/g, "-")}_${new Date()
          .toJSON()
          .slice(0, 10)}.pdf`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  const processXeroPDFDownload = (name) => {
    const orderId = order_id;
    const data = {
      id: id,
      params: {
        name: name,
        seller: authData.defaultSeller?.value,
      },
    };
    console.log(data);
    dispatch(downloadSalesOrderXeroInvoicePdf(data)).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${getValues("invoice_no")}_${name.replace(/\s+/g, "-")}_${new Date()
          .toJSON()
          .slice(0, 10)}.pdf`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  const paymentTypePromiseOption = (inputValue) =>
    new Promise((resolve) => {
      const data = {
        value: inputValue,
      };
      setTimeout(() => {
        resolve(
          mapSearchPaymentMethodAutoSuggestion(
            getPaymentAutoSuggestionApi(data)
          )
        );
      });
    });

  const downloadInvoice = (name, isCommercial) => {
    processPDFDownload(name, isCommercial);
  };

  const downloadRMA = (name, id) => {
    processRMAPDFDownload(name, id);
  };

  const downloadXeroInvoice = () => {
    processXeroPDFDownload("Xero Invoice");
  };

  // const onChangeDialog = (isTrigger) => {
  //   trigger("ordered_products");
  //   trigger("total_net_weight");
  // };

  const onChangeDialog = (isTrigger) => {
    trigger("ordered_products");
    trigger("total_net_weight");

    if (isTrigger) {
      // alert("check", isProductValueChange);
      setIsDiscountValueChange(!isDiscountValueChange);
      setIsProductValueChange(!isProductValueChange);
    }
  };

  const handleConfirmInvoiceClick = () => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Do you really want to confirm this invoice?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, confirm it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        confirmInvoice(id, order_id);
      }
    });
  };

  const confirmInvoice = (id, order_id) => {
    dispatch(confirmSalesOrderInvoice({ id: id, order_id: order_id }))
      .then((res) => {
        setShowReturn(true);
      })
      .catch((error) => {
        console.error("Error confirming Sales Order Invoice:", error);
      });
  };

  return (
    <>
      <Row className="mt-3">
        <Col
          md={12}
          className={
            salesOrderInvoiceState.orderInvoices.length > 5 ? "slick-arrow" : ""
          }
        >
          <Slider {...settings}>
            {salesOrderInvoiceState.orderInvoices.map((item, i) => (
              <div className="p-1 " key={i}>
                <Card className={id == item.id ? "active-invoice" : ""}>
                  <CardBody>
                    <Link
                      to={`${process.env.PUBLIC_URL}/order/sale/${order_id}/billing/invoice/${item.id}`}
                    >
                      <p className="font-weight-bolder f-14">
                        Invoice ID : {item.invoice_no}
                      </p>
                    </Link>
                    <p>Date : {item.created_at}</p>
                  </CardBody>
                </Card>
              </div>
            ))}
          </Slider>
        </Col>
      </Row>

      {id && (
        <Row>
          <Col md={12}>
            <Card>
              <CardBody style={{ padding: "24px" }}>
                <div className="d-flex justify-content-between mb-2 px-2">
                  {!isEditable && !showReturn ? (
                    <>
                      <Button
                        color="primary"
                        type="button"
                        className="btn btn-sm btn-primary"
                        style={{ height: 38 }}
                        onClick={handleConfirmInvoiceClick}
                      >
                        Confirm Invoice
                      </Button>
                    </>
                  ) : (
                    <div></div>
                  )}

                  <div className="d-flex">
                    {(!getValues("is_online") ||
                      !getValues("is_product_edit_disable")) && (
                      <Link
                        className="btn btn-primary pull-right ml-2"
                        to={`${process.env.PUBLIC_URL}/order/sale/${order_id}/billing/invoice`}
                      >
                        Generate New Invoice
                      </Link>
                    )}

                    {getValues("return_request_items")?.length > 0 && (
                      <Dropdown
                        isOpen={dropdownRMAOpen}
                        toggle={toggleRMADropdown}
                      >
                        <DropdownToggle
                          color="warning"
                          className="btn btn-sm btn-warning pull-right ml-2"
                          style={{
                            height: 38,
                            marginRight: 8,
                            fontSize: 14,
                            fontWeight: "bold",
                            letterSpacing: "1.1px",
                            font: "apple-System,Arial,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,STXihei,sans-serif",
                          }}
                        >
                          Download RMA{" "}
                          <i
                            className="fa fa-caret-down"
                            aria-hidden="true"
                          ></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          {getValues("return_request_items").map((item) => (
                            <DropdownItem
                              key={item.id}
                              onClick={() => {
                                downloadRMA(`RMA-${item.id}`, item.id);
                              }}
                            >
                              {`RMA-${item.id}`}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    )}

                    {showReturn && (
                      <Link
                        // className="btn btn-primary pull-right ml-2"
                        className={`btn btn-info pull-right ml-2`}
                        to={`${process.env.PUBLIC_URL}/order/sale/${order_id}/return/${id}`}
                      >
                        Return Invoice
                      </Link>
                    )}

                    <Dropdown
                      isOpen={dropdownStatusOpen}
                      toggle={toggleStatusDropdown}
                    >
                      <DropdownToggle
                        color="warning"
                        className="btn btn-sm btn-warning pull-right ml-2"
                        style={{
                          height: 38,
                          marginRight: 8,
                          fontSize: 14,
                          fontWeight: "bold",
                          letterSpacing: "1.1px",
                          font: "apple-System,Arial,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,STXihei,sans-serif;",
                        }}
                      >
                        Download{" "}
                        <i className="fa fa-caret-down" aria-hidden="true"></i>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => {
                            downloadInvoice("Invoice", 0);
                          }}
                        >
                          Local Invoice
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            downloadInvoice("Commercial Invoice", 1);
                          }}
                        >
                          Commercial Invoice
                        </DropdownItem>
                        {getValues("xero_id") && (
                          <DropdownItem onClick={downloadXeroInvoice}>
                            Xero Invoice
                          </DropdownItem>
                        )}
                      </DropdownMenu>
                    </Dropdown>

                    {/* {!getValues("xero_id") && (
                  <div className="pull-right ml-2">
                    <ButtonLoader
                      disabled={xeroButtonDisable}
                      color="info"
                      value="1"
                      type="button"
                      onClick={() => {
                        sendToXero();
                      }}
                      btntext="Sent To Quickbooks"
                    ></ButtonLoader>
                  </div>
                )} */}

                    {((!getValues("xero_id") ||
                      (getValues("is_online") &&
                        getValues("is_product_edit_disable"))) &&
                      !showReturn && (
                        <a
                          className="btn btn-success pull-right"
                          onClick={() => {
                            setIsEditable(!isEditable);
                          }}
                        >
                          {isEditable ? "View" : "Edit"}
                        </a>
                      )) ||
                      ""}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}

      <Row className="mt-3">
        {id && (
          <div style={{ position: "fixed", top: "50%", zIndex: 10, right: 0 }}>
            <LogActivity log="Sales Order Invoice" id={id}></LogActivity>
          </div>
        )}
        <Col md={9}>
          <Form noValidate="" onSubmit={handleSubmit(onSubmit)}>
            {isEditable && !getValues("is_product_edit_disable") ? (
              <>
                {getValues("ordered_products").length > 0 ? (
                  <>
                    <Row>
                      <Col md={12}>
                        <Card>
                          <CardBody>
                            <Row>
                              <Col md={3}>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    Terms
                                  </Label>
                                  <Select
                                    placeholder="Select Terms"
                                    type="select"
                                    name="term"
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    styles={{
                                      menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 100,
                                      }),
                                    }}
                                    value={getValues("terms")}
                                    isClearable
                                    options={
                                      salesOrderInvoiceState.formSettings.terms
                                    }
                                    onChange={(e) => {
                                      setValue("terms", e);
                                      const dueDate = moment()
                                        .add(e.no_of_days, "days")
                                        .format("YYYY-MM-DD");
                                      setValue("due_date", dueDate);
                                      trigger("terms");
                                      trigger("due_date");
                                    }}
                                    className={` ${
                                      errors.trackingName ? "is-invalid" : ""
                                    }`}
                                  />
                                  <div style={{ color: "red" }}>
                                    {errors.terms?.message}
                                  </div>
                                </FormGroup>
                              </Col>
                              <Col md={3}>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    Due Date
                                  </Label>
                                  <DatePicker
                                    name="dispatchDate"
                                    className="form-control"
                                    selected={
                                      getValues("due_date")
                                        ? new Date(getValues("due_date"))
                                        : null
                                    }
                                    onChange={(date) => {
                                      setValue("due_date", date);
                                      trigger("due_date");
                                    }}
                                    minDate={moment().toDate()}
                                    style={{ minHeight: "auto" }}
                                    dateFormat="dd-MM-yyyy"
                                  />
                                  <div style={{ color: "red" }}>
                                    {errors.due_date?.message}
                                  </div>
                                </FormGroup>
                              </Col>
                              <Col md={3}>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    Payment Method
                                  </Label>
                                  <AsyncSelect
                                    className={` ${
                                      errors.customer ? "is-invalid" : ""
                                    }`}
                                    isClearable
                                    cacheOptions
                                    defaultOptions
                                    defaultValue={getValues("payment_method")}
                                    loadOptions={paymentTypePromiseOption}
                                    onChange={(e) => {
                                      setValue("payment_method", e);
                                      trigger("payment_method");

                                      if (e && e.value === 1) {
                                        setShowBankDropdown(true);
                                      } else {
                                        setShowBankDropdown(false);
                                        setValue("bank", null);
                                      }
                                    }}
                                  />
                                  <div style={{ color: "red" }}>
                                    {errors.bank?.message}
                                  </div>
                                </FormGroup>
                              </Col>

                              {showBankDropdown && (
                                <Col md={3}>
                                  <FormGroup>
                                    <Label className="col-form-label">
                                      Bank
                                    </Label>
                                    <Select
                                      placeholder="Select Bank"
                                      type="select"
                                      name="bank"
                                      getOptionLabel={(option) => option.name}
                                      getOptionValue={(option) => option.id}
                                      styles={{
                                        menuPortal: (base) => ({
                                          ...base,
                                          zIndex: 100,
                                        }),
                                      }}
                                      value={getValues("bank")}
                                      isClearable
                                      options={
                                        salesOrderInvoiceState.formSettings
                                          .banks
                                      }
                                      onChange={(e) => {
                                        setValue("bank", e);
                                        trigger("bank");
                                      }}
                                      className={` ${
                                        errors.bank ? "is-invalid" : ""
                                      }`}
                                    />
                                    <div style={{ color: "red" }}>
                                      {errors.bank?.message}
                                    </div>
                                  </FormGroup>
                                </Col>
                              )}

                              <Col md={3}>
                                <FormGroup>
                                  <Label className="col-form-label">
                                    Trade Option
                                  </Label>
                                  <Select
                                    placeholder="Select Trade Option"
                                    type="select"
                                    name="trade_option"
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    styles={{
                                      menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 100,
                                      }),
                                    }}
                                    value={getValues("trade_option")}
                                    isClearable
                                    options={
                                      salesOrderInvoiceState.formSettings
                                        .term_trade_options
                                    }
                                    onChange={(e) => {
                                      setValue("trade_option", e);
                                      trigger("trade_option");
                                    }}
                                    className={` ${
                                      errors.trade_option ? "is-invalid" : ""
                                    }`}
                                  />
                                  <div style={{ color: "red" }}>
                                    {errors.trade_option?.message}
                                  </div>
                                </FormGroup>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Card>
                          <CardBody>
                            <p>Products</p>
                            <div className="selection-grid">
                              <SaleOrderProductInvoiceJqx
                                setValue={setValue}
                                getValues={getValues}
                                trigger={trigger}
                                orderedProducts={getValues("ordered_products")}
                                isProductValueChange={isProductValueChange}
                                isDiscountValueChange={isDiscountValueChange}
                                // setIsDiscountValueChange={setIsDiscountValueChange}
                                onChangeDialog={onChangeDialog}
                                currency={salesOrderState.orderData.symbol}
                              ></SaleOrderProductInvoiceJqx>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <PickUpDetail
                          setValue={setValue}
                          getValues={getValues}
                          trigger={trigger}
                          errors={errors}
                          id={id}
                          register={register}
                        ></PickUpDetail>
                      </Col>
                      <Col md={8}>
                        {getValues("ordered_products").length > 0 && (
                          <div>
                            <SaleOrderPayment
                              setValue={setValue}
                              getValues={getValues}
                              trigger={trigger}
                              currency={salesOrderState.orderData.symbol}
                            ></SaleOrderPayment>
                            <div
                              style={{
                                color: "red",
                                float: "right",
                              }}
                            >
                              {errors.shipping?.message}
                            </div>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </>
                ) : (
                  <div className="search-not-found text-center">
                    <div>
                      <img
                        className="img-fluid second-search"
                        src={require("../../../../../assets/images/search-not-found.png")}
                        alt=""
                      />
                      <p> All Ordered items are invoiced</p>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <SalesOrderInvoiceView
                isEditable={isEditable}
                invoiceData={getValues}
                setValue={setValue}
                getValues={getValues}
                trigger={trigger}
                errors={errors}
                id={id}
                register={register}
                currency={salesOrderState?.orderData?.symbol}
              />
            )}

            {getValues("ordered_products").filter(function (v, i) {
              return v.is_selected;
            }).length > 0 &&
              isEditable && (
                <Row>
                  <Col md={12}>
                    <Card>
                      <CardBody>
                        <div className="pull-right">
                          <ButtonLoader
                            disabled={buttonDisable}
                            color="primary"
                            value="1"
                            type="submit"
                            btntext="Save"
                          ></ButtonLoader>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}
          </Form>
        </Col>
        <Col md={3}>
          <BillingOrderDetail
            orderProducts={getValues("ordered_products").filter(function (
              v,
              i
            ) {
              return v.is_selected;
            })}
          ></BillingOrderDetail>
        </Col>
      </Row>
      <Row className="mt-3"></Row>
      <OrderDiscount
        orderSetValue={setValue}
        orderTrigger={trigger}
        orderGetValues={getValues}
      ></OrderDiscount>
      <OrderShipment
        draftOrderState={draftOrderState}
        orderSetValue={setValue}
        orderTrigger={trigger}
        orderGetValues={getValues}
      ></OrderShipment>
      <OrderTax
        orderSetValue={setValue}
        orderTrigger={trigger}
        orderGetValues={getValues}
      ></OrderTax>
      <SalesOrderDuty
        orderSetValue={setValue}
        orderTrigger={trigger}
        orderGetValues={getValues}
      ></SalesOrderDuty>
      {/* <OrderInvoiceItemDiscount
        rowdata={selectedRowData}
        orderGetValues={getValues}
        orderSetValue={setValue}
        orderTrigger={trigger}
        onChangeDialog={onChangeDialog}
      ></OrderInvoiceItemDiscount> */}
    </>
  );
};
export default withReducer([{ salesOrderInvoice: salesOrderInvoiceReducer }])(
  BillIngInvoice
);
